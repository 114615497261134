import React from "react";
import TableHeader from "./tableHeader";
import CustomTableBody from "./tableBody";
import "./index.css";

const TasksTable = ({
  rows,
  columns,
  options,
  handleSelectedUser,
  handleDeSelectedUser,
}) => {
  return (
    <div className="row projects-block ">
      <div className="col-md-12 " style={{ padding: "0" }}>
        <div className="project_pages-list-block ">
          <table
            className="table"
            style={{
              backgroundColor: "#fdfdfd",
              border: "1px solid #e8e8e8",
              boxShadow: "0 0.15rem 0.25rem rgb(0 0 0 / 8%)",
            }}
          >
            <CustomTableBody
              rows={rows}
              columns={columns}
              options={options}
              handleSelectedUser={handleSelectedUser}
              handleDeSelectedUser={handleDeSelectedUser}
            />
          </table>
        </div>
      </div>
    </div>
  );
};

export default TasksTable;
