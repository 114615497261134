import React, { useState, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import { useNavigate, useParams } from "react-router";
import "react-quill/dist/quill.snow.css";
import "./client-page.css";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import Spinner from "../Spinner";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Parser from "html-react-parser";
import Pagination from "react-js-pagination";
import MagicUrl from "quill-magic-url";

Quill.register("modules/magicUrl", MagicUrl);
const customStylesProject = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    height: "650px",
    width: "740px",
    padding: "0px",
    overflow: "visible",
  },
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    height: "35%",
    width: "400px",
    padding: "0px",
    overflow: "hidden",
  },
};
const customStylesEdit = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    height: "350px",
    width: "410px",
    padding: "0px",
    overflow: "visible",
  },
};
export const ClientPage = () => {
  console.error = (function () {
    var error = console.error;

    return function (exception) {
      if (
        (exception + "").indexOf(
          "Warning: A component is `contentEditable`"
        ) !== 0
      ) {
        error.apply(console, arguments);
      }
    };
  })();
  const { client_id } = useParams();
  const [quote_id, setQuote_id] = useState("");
  const [project_id, setProject_id] = useState("");
  const [project_name, setproject_name] = useState("");
  const [editorHtml, setEditorHtml] = useState("");
  const [clientDetails, setClientDetails] = useState([]);
  const [clientupdates, setClientUpdates] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingupdate, setLoadingupdate] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [refresh1, setRefresh1] = useState(false);
  const [title, setTitle] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [client_list, setClient_List] = useState([]);
  const [displaydate, setDate] = useState("");
  const [status, setStatus] = useState("");
  const [project_status, setProjectStatus] = useState("");
  const [modalEditIsOpen, setEditIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [modalProjectIsOpen, setProjectIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [on_board_information, seton_board_information] = useState("");

  const openProjectModal = (
    proj_id,
    proj_name,
    s_date,
    d_date,
    info,
    pro_status
  ) => {
    setStartDate(new Date(s_date));
    setEndDate(new Date(d_date));
    seton_board_information(info);
    setproject_name(proj_name);
    setProject_id(proj_id);
    setProjectStatus(pro_status);

    setProjectIsOpen(true);
  };

  const closeProjectModal = () => {
    setProjectIsOpen(false);
  };
  const handleConvertProject = async () => {
    setLoading(true);
    let start_date = moment(startDate).format("YYYY-MM-DD");
    let end_date = moment(endDate).format("YYYY-MM-DD");
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/edit_project`,
      {
        project_id,
        project_name,
        start_date,
        end_date,
        on_board_information,
        status: project_status,
      } //add service properties
    );
    setProjectIsOpen(false);
    setRefresh(!refresh);
  };
  const openModal = () => {
    setTitle("");
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
  }
  let navigate = useNavigate();

  const getData = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/client_details`,
      { client_id }
    );
    const get_clients = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/get_all_clients`
      // { limit, page }
    );

    setClient_List(get_clients.data);
    setClientDetails(response.data);
    setLoading(false);
  };
  const clientUpdateData = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/client_updates`,
      { client_id, page }
    );
    setClientUpdates(response.data);
  };
  useEffect(() => {
    if (localStorage.role === "user") {
      navigate("/");
    }
    getData();
  }, [refresh]);
  useEffect(() => {
    if (localStorage.role === "user") {
      navigate("/");
    }

    clientUpdateData();
    setLoadingupdate(false);
  }, [refresh1]);

  const handleDeleteQuote = async (quote_id) => {
    let result = Swal.fire({
      title: "Are you sure?",
      text: "The deleted qoute can not be reverted. Do you want to continue?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });
    if ((await result).isConfirmed) {
      setLoading(true);
      await axios.post(`${process.env.REACT_APP_BASE_URL}/api/delete_quote`, {
        quote_id,
      });

      setRefresh(!refresh);
    }
  };

  const handleDeleteProject = async (project_id) => {
    let result = Swal.fire({
      title: "Are you sure?",
      text: "The deleted project can not be reverted. Do you want to continue?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });
    if ((await result).isConfirmed) {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/delete_project`,
        { project_id }
      );

      setRefresh(!refresh);
    }
  };
  const setDateValue = (date) => {
    setStartDate(date);
  };
  const handlePageChange = (currpage) => {
    setLoadingupdate(true);
    setPage(currpage);
    setRefresh1(!refresh1);
  };
  const handleAddQuote = async () => {
    setLoading(true);
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/add_quote`,
      {
        client_id,
        title,
      } //add service properties
    );
    navigate(`/quote/${response.data?.quote_id}`);

    setIsOpen(false);

    setRefresh(!refresh);
    setIsOpen(false);
  };
  const openEditModal = (id, name, stats, create_date) => {
    setTitle(name);
    setQuote_id(id);
    setStatus(stats);
    setDate(new Date(create_date));
    setEditIsOpen(true);
  };
  function closeEditModal() {
    setEditIsOpen(false);
  }

  const handleEditorChange = (html) => {
    seton_board_information(html);
  };

  const handleEditQuote = async () => {
    setLoading(true);
    let date = moment(displaydate).format("YYYY-MM-DD");
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/edit_quote_details`,
      {
        title,
        quote_id,
        date,
        status,
      }
    );

    setTitle("");
    setQuote_id("");

    setEditIsOpen(false);

    setRefresh(!refresh);
  };

  const modules = {
    magicUrl: true,
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ header: [1, 2, false] }],
      ["clean"],
      ["link"],
    ],
  };
  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        <div
          style={{
            display: "flex",
            background: "grey",
            justifyContent: "space-between",
            padding: "0px",
          }}
        >
          <div
            style={{ marginLeft: "10px", marginTop: "4px", fontWeight: "bold" }}
          >
            {"Add Quote"}
          </div>

          <div
            onClick={() => setIsOpen(false)}
            style={{
              marginRight: "5px",

              cursor: "pointer",
            }}
          >
            <img
              src="/assests/img/close-modal.svg"
              width="20"
              alt="modal close"
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "35px" }}>
          <div className=" col-md-3 label">Quote:</div>
          <div className="col-md-6">
            <input
              style={{ width: "220px" }}
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
              // className="shadow-sm"
              type="text"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </div>

        <button
          type="btn"
          className="all-modal-button btn btn-primary"
          style={{ float: "right", marginRight: "55px", marginTop: "38px" }}
          onClick={() => handleAddQuote()}
        >
          Continue
        </button>
      </Modal>

      <Modal
        isOpen={modalEditIsOpen}
        onRequestClose={closeEditModal}
        style={customStylesEdit}
        ariaHideApp={false}
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        <div
          style={{
            display: "flex",
            background: "grey",
            justifyContent: "space-between",
            padding: "0px",
          }}
        >
          <div
            style={{ marginLeft: "10px", marginTop: "4px", fontWeight: "bold" }}
          >
            {"Edit Quote"}
          </div>

          <div
            onClick={() => setEditIsOpen(false)}
            style={{
              marginRight: "5px",
              cursor: "pointer",
            }}
          >
            <img
              src="/assests/img/close-modal.svg"
              width="20"
              alt="modal close"
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "35px" }}>
          <div className=" col-md-3 label">Quote:</div>
          <div className="col-md-7">
            <input
              style={{ width: "220px" }}
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
              // className="shadow-sm"
              type="text"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "35px" }}>
          <div className=" col-md-3 label">Date:</div>
          <div className="col-md-7">
            <DatePicker
              style={{ width: "220px" }}
              selected={displaydate}
              onChange={(d) => setDate(d)}
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "35px" }}>
          <div className=" col-md-3 label">Status:</div>
          <div className="col-md-7">
            <select
              style={{ width: "220px" }}
              name="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="form-select-sm"
              aria-label=".form-select-sm example"
            >
              <option value></option>
              <option value={"won"}>WON</option>
              <option value={"lost"}>LOST</option>
            </select>
          </div>
        </div>

        <button
          type="btn"
          className="all-modal-button btn btn-primary"
          style={{ float: "right", marginRight: "75px", marginTop: "38px" }}
          onClick={() => handleEditQuote()}
        >
          Save
        </button>
      </Modal>

      <Modal
        isOpen={modalProjectIsOpen}
        onRequestClose={closeProjectModal}
        style={customStylesProject}
        ariaHideApp={false}
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        <div
          style={{
            display: "flex",
            background: "grey",
            justifyContent: "space-between",
            padding: "0px",
          }}
        >
          <div
            style={{ marginLeft: "10px", marginTop: "4px", fontWeight: "bold" }}
          >
            {"Edit Project"}
          </div>

          <div
            onClick={() => setProjectIsOpen(false)}
            style={{
              marginRight: "5px",

              cursor: "pointer",
            }}
          >
            <img
              src="/assests/img/close-modal.svg"
              width="20"
              alt="modal close"
            />
          </div>
        </div>

        <div className="row" style={{ marginLeft: "15px", marginTop: "30px" }}>
          <div className="col-md-3 label">Project :</div>
          <div className="col-md-7">
            <input
              style={{ width: "220px" }}
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
              // className="shadow-sm"
              type="text"
              name="project_name"
              value={project_name}
              onChange={(e) => setproject_name(e.target.value)}
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "35px" }}>
          <div className=" col-md-3 label">Status:</div>
          <div className="col-md-7">
            <select
              style={{ width: "220px" }}
              name="status"
              value={project_status}
              onChange={(e) => setProjectStatus(e.target.value)}
              className="form-select-sm"
              aria-label=".form-select-sm example"
            >
              <option value></option>
              <option value={"Pending"}>Pending</option>
              <option value={"Archived"}>Archived</option>
              <option value={"Completed"}>Completed</option>
              <option value={"Active"}>Active</option>
            </select>
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "30px" }}>
          <div className="col-md-3 label">Start Date:</div>
          <div className="col-md-7">
            <DatePicker
              style={{ width: "220px", border: "1px solid" }}
              selected={startDate}
              onChange={(date) => setDateValue(date)}
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "30px" }}>
          <div className="col-md-3 label">Due Date :</div>
          <div className="col-md-7">
            <DatePicker
              style={{ width: "220px" }}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "35px" }}>
          <div className=" col-md-3 label">OnBoard Info:</div>
          <div className="col-md-8">
            <ReactQuill
              style={{ height: "200px", background: "white" }}
              value={on_board_information}
              onChange={handleEditorChange}
              modules={modules}
            />
          </div>
        </div>

        <button
          type="btn"
          className="all-modal-button btn btn-primary"
          style={{ float: "right", marginRight: "80px", marginTop: "70px" }}
          onClick={() => handleConvertProject()}
        >
          Save
        </button>
      </Modal>
      <div className="client_page">
        <div className="container client_page-Block">
          <div className="client_page-header shadow-sm">
            <div className="client_page-heading">
              <h1>
                Clients &nbsp; &rarr; &nbsp;{" "}
                {clientDetails.client_details?.company} {"    "}{" "}
              </h1>
            </div>
            <div className="container">
              <div className="row client_page-detail-section bg-white">
                <div className="col-md-12">
                  <h4 className="info-heading">Client Info</h4>
                  <div
                  //
                  >
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="col-md-6 client-info-block shadow-sm"
                        style={{ width: "35%", padding: "10px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            margin: "0px",
                            fontSize: "14px",
                          }}
                        >
                          <div className="col-4">Business Name: </div>
                          {clientDetails.client_details?.company}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            margin: "0px",
                            fontSize: "14px",
                          }}
                        >
                          <div className="col-4"> Owner Name:</div>{" "}
                          <div>
                            {clientDetails.client_details?.first_name} {""}
                            {clientDetails.client_details?.last_name}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            margin: "0px",
                            fontSize: "14px",
                          }}
                        >
                          <div className="col-4"> Phone Number: </div>{" "}
                          {clientDetails.client_details?.phone}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            margin: "0px",
                            fontSize: "14px",
                          }}
                        >
                          <div className="col-4"> Email: </div>{" "}
                          {clientDetails.client_details?.email}
                        </div>
                      </div>

                      <div
                        className="col-md-6 notes-block"
                        // style={{
                        //   width: "5%",
                        // }}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="notes-paragraph-section"
                          // style={{ maxWidth: "120px" }}
                        >
                          <p>
                            Account Manager:{" "}
                            <span className="cc">
                              {clientDetails?.account_manager?.first_name}{" "}
                              {clientDetails?.account_manager?.last_name}
                            </span>
                          </p>
                          <p>
                            Client Created On:{" "}
                            <span>
                              {" "}
                              {moment
                                .utc(clientDetails.client_details?.created_at)
                                .format("MM/DD/YYYY")}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row invoice-client_page-block">
                    <div className="col-md-12">
                      <div className="client_pages-list-block">
                        <div
                          className="row"
                          // style={{ borderBottom: "1px solid grey" }}
                        >
                          <div
                            className="table-client_page-heading col-sm-4"
                            style={{
                              fontSize: "18px",
                              fontFamily: "SF-Compact-Display-Regular",
                              fontWeight: "bold",
                              // width: "100px",
                              // borderBottom: "0.5px solid gray",
                              // borderWidth: "0.5px",
                              // borderColor: "#D3D3D3",
                              marginBottom: "8px",
                            }}
                          >
                            Quotes
                          </div>

                          <div className="col-sm-8 ">
                            <div
                              onClick={() => openModal()}
                              style={{
                                float: "right",
                                cursor: "pointer",
                                background: "#0b55b0",
                                color: "#fff",
                                fontFamily: "SF-Compact-Display-Regular",
                                width: "85px",
                                fontSize: "14px",
                                padding: "5px",
                              }}
                            >
                              +Add Quote
                            </div>
                          </div>
                        </div>
                        <table className="table">
                          <thead>
                            <tr style={{ background: "#e9ecef" }}>
                              <th className="table-client_page-heading">
                                Date
                              </th>
                              <th
                                className="table-client_page-heading"
                                style={{ maxWidth: "155px" }}
                              >
                                Name
                              </th>
                              <th className="table-client_page-heading">
                                Value
                              </th>
                              <th className="table-client_page-heading">
                                Status
                              </th>
                              <th className="table-client_page-heading"></th>
                              <th className="table-client_page-heading"></th>
                            </tr>
                          </thead>
                          <tbody className="table-group-divider">
                            {clientDetails.client_quotes?.map((quote) => (
                              <tr style={{ cursor: "pointer" }} key={quote.id}>
                                <td
                                  onClick={() => navigate(`/quote/${quote.id}`)}
                                >
                                  {moment.utc(quote.date).format("MM/DD/YYYY")}
                                </td>
                                <td
                                  style={{ maxWidth: "160px" }}
                                  onClick={() => navigate(`/quote/${quote.id}`)}
                                >
                                  {quote.title}
                                </td>
                                <td
                                  onClick={() => navigate(`/quote/${quote.id}`)}
                                >
                                  ${Number(quote?.total_price).toLocaleString()}
                                </td>
                                <td
                                  onClick={() => navigate(`/quote/${quote.id}`)}
                                >
                                  <span
                                    className={
                                      quote.status === null ? "lost" : "won"
                                    }
                                  >
                                    {quote.status === null
                                      ? "Pending"
                                      : quote.status}
                                  </span>
                                </td>
                                <td
                                  onClick={() =>
                                    openEditModal(
                                      quote.id,
                                      quote.title,
                                      quote.status,
                                      quote.date
                                    )
                                  }
                                >
                                  {" "}
                                  Edit
                                </td>
                                <td>
                                  <img
                                    onClick={() => handleDeleteQuote(quote.id)}
                                    style={{ cursor: "pointer" }}
                                    src="/assests/img/close.svg"
                                    width="20"
                                    alt="close"
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row projects-block">
                    <div className="col-md-12">
                      <div className="client_pages-list-block">
                        <div
                          className="col-sm-4"
                          style={{
                            fontSize: "18px",
                            fontFamily: "SF-Compact-Display-Regular",
                            fontWeight: "bold",

                            marginBottom: "8px",
                          }}
                        >
                          Projects
                        </div>
                        <table className="table">
                          <thead>
                            <tr></tr>
                          </thead>
                          <thead>
                            <tr style={{ background: "#e9ecef" }}>
                              <th className="table-client_page-heading">
                                Date
                              </th>
                              <th className="table-client_page-heading">
                                Name
                              </th>
                              <th className="table-client_page-heading">
                                Value
                              </th>
                              <th className="table-client_page-heading">
                                Assigned to
                              </th>
                              <th className="table-client_page-heading"></th>
                              <th className="table-client_page-heading"></th>
                            </tr>
                          </thead>
                          <tbody className="table-group-divider">
                            {clientDetails.client_projects?.map(
                              (project, i) => (
                                <tr style={{ cursor: "pointer" }} key={i}>
                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/project-page/${project?.project.id}`
                                      )
                                    }
                                    style={{ minWidth: "80px" }}
                                  >
                                    {moment
                                      .utc(project.project.created_at)
                                      .format("MM/DD/YYYY")}
                                  </td>
                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/project-page/${project?.project.id}`
                                      )
                                    }
                                  >
                                    {project?.project.project_name}
                                  </td>
                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/project-page/${project?.project.id}`
                                      )
                                    }
                                  >
                                    $
                                    {Number(
                                      project?.project.total_price
                                    ).toLocaleString()}
                                  </td>
                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/project-page/${project?.project.id}`
                                      )
                                    }
                                  >
                                    {project.assigned_users?.map((user) => {
                                      return (
                                        <span
                                          className="br"
                                          style={{
                                            marginRight: "3px",
                                            marginBottom: "2px",
                                          }}
                                          key={user.id}
                                        >
                                          {user.first_name} {user.last_name}
                                        </span>
                                      );
                                    })}
                                  </td>
                                  <td
                                    onClick={() =>
                                      openProjectModal(
                                        project.project.id,
                                        project.project.project_name,
                                        project.project.start_date,
                                        project.project.due_date,
                                        project.project.on_board_information,
                                        project.project.project_status
                                      )
                                    }
                                  >
                                    {" "}
                                    Edit
                                  </td>
                                  <td>
                                    <img
                                      onClick={() =>
                                        handleDeleteProject(project?.project.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                      src="/assests/img/close.svg"
                                      width="20"
                                      alt="close"
                                    />
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row updates-main-block ">
                    <h3 className="col-sm-4 table_client_page_headings">
                      Updates
                    </h3>
                    <div className="col-md-12">
                      <div className="updates-block shadow-sm">
                        <div className="updates-section">
                          <div className="row">
                            <div className="col-12">
                              <div className="row">
                                <div
                                  className="col-1"
                                  style={{
                                    minWidth: "100px",
                                    fontSize: "16px",
                                    fontFamily: "SF-Compact-Display-Medium",
                                    marginBottom: 0,
                                  }}
                                >
                                  <h4
                                    style={{
                                      fontSize: "14px",
                                      fontFamily: "SF-Compact-Display-Medium",
                                      marginBottom: 0,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Date
                                  </h4>
                                </div>

                                <div className="col-2">
                                  <h4
                                    style={{
                                      fontSize: "14px",
                                      fontFamily: "SF-Compact-Display-Medium",
                                      marginBottom: 0,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Task
                                  </h4>
                                </div>
                                <div className="col-2">
                                  <h4
                                    style={{
                                      fontSize: "14px",
                                      fontFamily: "SF-Compact-Display-Medium",
                                      marginBottom: 0,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    User
                                  </h4>
                                </div>
                                <div className="col-2">
                                  <h4
                                    style={{
                                      fontSize: "14px",
                                      fontFamily: "SF-Compact-Display-Medium",
                                      marginBottom: 0,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Project
                                  </h4>
                                </div>
                                <div className="col-4">
                                  <h4
                                    style={{
                                      fontSize: "14px",
                                      fontFamily: "SF-Compact-Display-Medium",
                                      marginBottom: 0,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Message
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="updates-paragraph-section">
                          {isLoadingupdate ? (
                            <Spinner />
                          ) : (
                            <div className="row">
                              <div className="col-12">
                                {clientupdates?.client_updates?.map((msg) => {
                                  return (
                                    <div className="row" key={msg?.id}>
                                      <div
                                        className="col-1"
                                        style={{
                                          minWidth: "100px",
                                          marginTop: "1%",
                                        }}
                                      >
                                        {moment
                                          .utc(msg?.created_at)
                                          .format("MM/DD/YYYY")}
                                      </div>

                                      <div
                                        style={{
                                          marginTop: "1%",
                                        }}
                                        className="col-2"
                                      >
                                        {msg?.task_name}
                                      </div>
                                      <div
                                        style={{
                                          marginTop: "1%",
                                        }}
                                        className="col-2"
                                      >
                                        {msg?.user}
                                      </div>
                                      <div
                                        style={{
                                          marginTop: "1%",
                                        }}
                                        className="col-2"
                                      >
                                        {msg?.project_name}
                                      </div>
                                      <div
                                        style={{
                                          textAlign: "justify",
                                          wordBreak: "break-word",
                                        }}
                                        className="col-4"
                                      >
                                        {Parser(msg?.message)}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  marginTop: "30px",
                                  marginRight: "50px",
                                }}
                              >
                                <Pagination
                                  activePage={page}
                                  itemsCountPerPage={limit}
                                  totalItemsCount={clientupdates?.count}
                                  pageRangeDisplayed={5}
                                  onChange={(page) => handlePageChange(page)}
                                  itemClass="page-item"
                                  linkClass="page-link"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container copy-writes">
          <footer className="row d-flex flex-wrap justify-content-between align-items-center my-4">
            <div className="col-md-4">
              <a
                href="/"
                className="footer-icon mb-3 me-2 mb-md-0 text-muted text-decoration-none"
              >
                <img src="assests/img/footer-icon.svg" alt="" height="15" />
              </a>
              <span className="text-muted">BusinessNET MarketingEngine</span>
              <br />
              <span className="text-muted1">Version 1.0</span>
            </div>

            <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
              <li className="ms-3">
                Privacy policy. Terms of Use. Copyright © 2022 BusinessNET.
              </li>
            </ul>
          </footer>
        </div>
      </div>
    </>
  );
};
