import { intersectionBy, isArray, isEmpty } from 'lodash';

export const mapUsersData = (task, users) => {
    
    let arr = [], taskSkillsRequired = [], userSkills = [];
    if (users.length > 0 && Object.keys(task).length > 0) {
        taskSkillsRequired = task.skills_required || [];
        arr = users.map(user => {
            userSkills = user.skills || [];
            if (userSkills.length > 0) {
                let overlappedArray = intersectionBy(userSkills, taskSkillsRequired, 'id');
                if (overlappedArray.length > 0) return { name: user?.first_name, userId: user?.id };
                return false;
            }
        });
        arr = arr.filter(v => v !== false);
    } return arr;
};

export const removeDuplicates = (arr) => {
    if (isArray(arr) && !isEmpty(arr)) {
        return [...new Set(arr)];
    } else return [];
};