import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

function CustomMenuItem(props) {
    const { user, index, isChecked } = props;
    // const [isChecked, setChecked] = useState(false);

    const onChange = () => {
        debugger
    }
    return (
        <MenuItem
            key={user?.userId}
            value={user}
        >
            <Checkbox checked={isChecked} />
            <ListItemText primary={user?.name} />
        </MenuItem>
    )
}

export default CustomMenuItem