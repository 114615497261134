import React, { useState, useEffect } from "react";
import "./project-list.css";
import { useNavigate } from "react-router";
import axios from "axios";
import moment from "moment";
import Spinner from "../Spinner";
import "react-quill/dist/quill.snow.css";
import Pagination from "react-js-pagination";
import Parser from "html-react-parser";
import { Multiselect } from "multiselect-react-dropdown";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
export const ProjectList = () => {
  console.error = (function () {
    var error = console.error;

    return function (exception) {
      if (
        (exception + "").indexOf(
          "Warning: A component is `contentEditable`"
        ) !== 0
      ) {
        error.apply(console, arguments);
      }
    };
  })();
  const [projectListDetails, setProjectListDetails] = useState([]);
  const [clientupdates, setClientUpdates] = useState([]);
  const [isLoadingFilter, setLoadingFilter] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingupdate, setLoadingupdate] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [refresh1, setRefresh1] = useState(false);
  const [page, setPage] = useState(1);
  const [page2, setPage2] = useState(1);
  const [limit, setLimit] = useState(10);
  const [limit2, setLimit2] = useState(10);
  const [filter_type, setFilter_type] = useState([]);
  const [project_status, setProject_status] = useState("Active");
  const [selected_project_status, setSelectedProjectstatus] = useState([]);
  const [user_ids, setUser_ids] = useState([]);
  const [selected_user_ids, setSelectedUserids] = useState([]);
  const [client_ids, setClient_ids] = useState([]);
  const [selected_client_ids, setSelectedClientids] = useState([]);
  const [all_users, setAll_users] = useState([]);
  const [all_clients, setAll_clients] = useState([]);
  const [sort_by, setSort_by] = useState("id");
  const [selected_sort_by, setSelectedSortby] = useState([]);
  let navigate = useNavigate();

  const getData = async () => {
    const response2 = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/filter_users`
    );
    const response_clients = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/get_client_list`
    );
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/filter_user_project_list`,
      {
        user_ids,
        page,
        limit,
        client_ids,
        project_status,
        sort_by,
      }
    );
    setAll_users(
      response2.data?.users.map((user) => {
        return {
          id: user?.id,
          name: user?.first_name + " " + user?.last_name,
        };
      })
    );
    setAll_clients(
      response_clients.data?.data?.map((user) => {
        return {
          id: user?.id,
          name: user?.company,
        };
      })
    );
    setProjectListDetails(response.data);
    setLoading(false);
    setLoadingFilter(false);
  };

  const clientUpdateData = async () => {
    setLoadingupdate(true);
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/get_user_project_list_updates`,
      {
        page: page2,
        filter_type,
        user_ids,
        project_status,
        client_ids,
        limit: limit2,
      }
    );
    setClientUpdates(response.data);

    setLoadingupdate(false);
  };
  useEffect(() => {
    getData();
  }, [refresh, page, user_ids, project_status, client_ids, sort_by]);

  useEffect(() => {
    clientUpdateData();
  }, [refresh1, filter_type, user_ids, project_status, client_ids, page2]);
  const handlePageChange = (currpage) => {
    setLoading(true);

    setPage(currpage);
    setRefresh(!refresh);
  };
  const handlePageChange2 = (currpage) => {
    setPage2(currpage);
  };

  const handleSelectFilterStatusType = async (data, item) => {
    if (item.id === undefined) {
      setSelectedProjectstatus([{ id: "Active", name: "Active" }]);

      setProject_status("all");

      setPage(1);
      setLoadingFilter(true);
    } else {
      setSelectedProjectstatus([{ id: item.id, name: item.name }]);
      setProject_status(item.id);
      setPage(1);

      setLoadingFilter(true);
      setRefresh(!refresh);
    }
  };

  const handleSelectSortby = async (data, item) => {
    if (item.id === undefined) {
      setSort_by("id");
      setSelectedSortby([{ id: "id", key: "Created At" }]);

      setPage(1);

      setLoadingFilter(true);
    } else {
      setSelectedSortby([{ id: item.id, key: item.key }]);

      setSort_by(item.id);

      setPage(1);

      setLoadingFilter(true);
      setRefresh(!refresh);
    }
  };

  const handleSelectFilter = (data, item) => {
    setUser_ids([...user_ids, item.id]);
    setSelectedUserids([
      ...selected_user_ids,
      { id: item.id, name: item.name },
    ]);
    setPage(1);

    setLoadingFilter(true);
  };
  const handleDeSelectedFilter = (data, item) => {
    let updatedids = user_ids.filter((e) => e !== item.id);
    let updated_ids = selected_user_ids.filter((e) => e.id !== item.id);
    setUser_ids(updatedids);
    setSelectedUserids(updated_ids);
    setPage(1);

    setLoadingFilter(true);
    // setLoadingupdate(true);
  };

  const handleSelectClientFilter = (data, item) => {
    setClient_ids([...client_ids, item.id]);
    setSelectedClientids([
      ...selected_client_ids,
      { id: item.id, name: item.name },
    ]);
    setPage(1);

    setLoadingFilter(true);
  };
  const handleDeSelectedClientFilter = (data, item) => {
    let updatedids = client_ids.filter((e) => e !== item.id);
    let updated_id = selected_client_ids.filter((e) => e.id !== item.id);
    setClient_ids(updatedids);
    setSelectedClientids(updated_id);
    setPage(1);

    setLoadingFilter(true);
  };

  let projectFilterOptions = [
    {
      id: "Active",
      name: "Active",
    },
    {
      id: "Pending",
      name: "Pending",
    },
    {
      id: "Archived",
      name: "Archived",
    },
    {
      id: "Completed",
      name: "Completed",
    },
    { id: "all", name: "All" },
  ];
  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <div className="project_list">
        <div className="container project_list-Block">
          <div className="project_list-header shadow-sm">
            <div className="project_list-heading">
              <h1>Projects</h1>
            </div>
            <div className="container bg-white">
              {localStorage.role === "admin" ? (
                <div className="row pt-2">
                  <div
                    className="col-md-1"
                    style={{ maxWidth: "59px", marginTop: "0.8%" }}
                  >
                    Filters:{" "}
                  </div>

                  <div className="col-md-2">
                    <Multiselect
                      options={all_users}
                      onSelect={handleSelectFilter} // Function will trigger on select event
                      onRemove={handleDeSelectedFilter}
                      displayValue="name"
                      hideSelectedList
                      showCheckbox
                      showArrow
                      placeholder={"Select Users"}
                      selectedValues={selected_user_ids}
                      avoidHighlightFirstOption="true"
                    />
                  </div>
                  <div className="col-md-2">
                    <Multiselect
                      options={projectFilterOptions}
                      singleSelect
                      selectedValues={selected_project_status}
                      onSelect={handleSelectFilterStatusType} // Function will trigger on select event
                      displayValue="name"
                      placeholder={"Select Status"}
                      avoidHighlightFirstOption="true"
                    />
                  </div>
                  <div className="col-md-2">
                    <Multiselect
                      options={all_clients}
                      onSelect={handleSelectClientFilter} // Function will trigger on select event
                      onRemove={handleDeSelectedClientFilter}
                      displayValue="name"
                      hideSelectedList
                      showCheckbox
                      showArrow
                      placeholder={"Select Client"}
                      selectedValues={selected_client_ids}
                      avoidHighlightFirstOption="true"
                    />
                  </div>

                  <div
                    className="col-md-4"
                    style={{
                      maxWidth: "300px",
                      marginTop: "0.8%",
                      textAlign: "right",
                    }}
                  >
                    Sort By:{" "}
                  </div>
                  <div className="col-md-2">
                    <Multiselect
                      options={[
                        { id: "id", key: "Created At" },
                        {
                          id: "project_name",
                          key: "Project Name",
                        },
                        {
                          id: "company",
                          key: "Client Name",
                        },
                        {
                          id: "start_date",
                          key: "Start Date",
                        },
                        {
                          id: "due_date",
                          key: "Due Date",
                        },
                      ]}
                      onSelect={handleSelectSortby} // Function will trigger on select event
                      singleSelect // Function will trigger on remove event
                      displayValue="key"
                      placeholder={"Select"}
                      selectedValues={selected_sort_by}
                      avoidHighlightFirstOption="true"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              {isLoadingFilter ? (
                <Spinner />
              ) : (
                <div className="row projects-block">
                  <div className="col-md-12">
                    <div className="project_lists-list-block">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="table-project_list-heading">
                              Client
                            </th>
                            <th className="table-project_list-heading">
                              Project
                            </th>
                            <th className="table-project_list-heading">
                              Services
                            </th>
                            <th className="table-project_list-heading">
                              Tasks
                            </th>
                            <th className="table-project_list-heading">
                              Start Date
                            </th>
                            <th className="table-project_list-heading">
                              Due Date
                            </th>
                            <th className="table-project_list-heading">
                              Status
                            </th>
                            <th className="table-project_list-heading">
                              Assigned To
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          className="table-group-divider"
                          style={{ cursor: "pointer" }}
                        >
                          {projectListDetails.data?.map((project) => {
                            return (
                              <tr
                                className="proejct-row"
                                key={project.id}
                                onClick={() =>
                                  navigate(`/project-page/${project.id}`)
                                }
                              >
                                <td>{project.client}</td>
                                <td style={{ maxWidth: "150px" }}>
                                  {project.project_name}
                                </td>
                                <td style={{ maxWidth: "170px" }}>
                                  {project.services.map((service) => (
                                    <span
                                      className="assign-to"
                                      key={service.id}
                                    >
                                      {service.service_name}
                                    </span>
                                  ))}
                                </td>
                                <td>
                                  {project.completed_tasks}/
                                  {project.total_tasks}
                                </td>
                                <td style={{ minWidth: "80px" }}>
                                  {project.start_date}
                                </td>
                                <td style={{ minWidth: "80px" }}>
                                  {project.due_date}
                                </td>
                                <td className="statusactive">
                                  <span>{project.project_status}</span>
                                </td>
                                <td style={{ minWidth: "160px" }}>
                                  {project.assigned_users?.map((user) => {
                                    return (
                                      <span className="assign-to" key={user.id}>
                                        {user.first_name} {user.last_name}
                                      </span>
                                    );
                                  })}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "30px",
                        marginRight: "50px",
                      }}
                    >
                      {" "}
                      <footer>
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={limit}
                          totalItemsCount={projectListDetails?.count}
                          pageRangeDisplayed={5}
                          onChange={(page) => handlePageChange(page)}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </footer>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="container project_list-Block update">
          <div className="row updates-main-block">
            <div className="col-md-12" style={{ padding: "0px" }}>
              <h3>Updates</h3>
              {isLoadingupdate ? (
                <Spinner />
              ) : (
                <div className="row">
                  <div className="col-md-12">
                    <div className="updates-block shadow-sm">
                      <div className="updates-section">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-1">
                              <h4>Date</h4>
                            </div>
                            <div className="col-2">
                              <h4>Client</h4>
                            </div>
                            <div className="col-2">
                              <h4>Project</h4>
                            </div>
                            <div className="col-2">
                              <h4>User</h4>
                            </div>
                            <div className="col-5">
                              <h4>Message</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="updates-paragraph-section">
                        <div className="row">
                          <div className="col-12">
                            {clientupdates?.updates?.map((msg) => {
                              return (
                                <div
                                  className="row"
                                  style={{ padding: "0.5%" }}
                                  key={msg.id}
                                >
                                  <div
                                    className="col-1"
                                    style={{ marginTop: "1%" }}
                                  >
                                    {moment
                                      .utc(msg?.created_at)
                                      .format("MM/DD/YYYY")}{" "}
                                  </div>
                                  <div
                                    className="col-2"
                                    style={{ marginTop: "1%" }}
                                  >
                                    {msg?.company}
                                  </div>
                                  <div
                                    className="col-2"
                                    style={{ marginTop: "1%" }}
                                  >
                                    {msg?.project}
                                  </div>
                                  <div
                                    className="col-2"
                                    style={{ marginTop: "1%" }}
                                  >
                                    {msg?.user}
                                  </div>
                                  <div
                                    className="col-5"
                                    style={{
                                      textAlign: "justify",
                                      wordBreak: "break-word",
                                      marginTop: "1%",
                                    }}
                                  >
                                    {Parser(msg?.message)}{" "}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: "30px",
                          marginRight: "50px",
                        }}
                      >
                        {" "}
                        <footer>
                          <Pagination
                            activePage={page2}
                            itemsCountPerPage={limit2}
                            totalItemsCount={clientupdates?.count}
                            pageRangeDisplayed={5}
                            onChange={(page) => handlePageChange2(page)}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </footer>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="container copy-writes">
          <footer className="row d-flex flex-wrap justify-content-between align-items-center my-4">
            <div className="col-md-4">
              <a
                href="/"
                className="footer-icon mb-3 me-2 mb-md-0 text-muted text-decoration-none"
              >
                <img src="assests/img/footer-icon.svg" alt="" height="15" />
              </a>
              <span className="text-muted">BusinessNET MarketingEngine</span>
              <br />
              <span className="text-muted1">Version 1.0</span>
            </div>

            <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
              <li className="ms-3">
                Privacy policy. Terms of Use. Copyright © 2022 BusinessNET.
              </li>
            </ul>
          </footer>
        </div>
      </div>
    </>
  );
};
