import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router";
import { mapUsersData } from "../dynamicCollapsible/utils";
import TableDropdown from "./tableDropdown";
import CustomRow from "../dynamicCollapsible/CustomRow";
import StyledComponentLibrary from "../dynamicCollapsible/styles";

const StyledHeaderTableRow =
  StyledComponentLibrary(TableRow).StyledHeaderTableRow;
const StyledHeaderCell = StyledComponentLibrary(TableCell).StyledHeaderCell;
const StyledBox = StyledComponentLibrary(Box).StyledBox;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomTableBody = ({
  rows,
  columns,
  options,
  handleSelectedUser,
  handleDeSelectedUser,
}) => {
  let navigate = useNavigate();
  let filteredOptions = [];
  const renderTableHeader = () => {
    return (
      <StyledHeaderTableRow>
        <StyledHeaderCell>
          <b>Task</b>
        </StyledHeaderCell>
        <StyledHeaderCell>
          <b>Status</b>
        </StyledHeaderCell>
        <StyledHeaderCell>
          <b>Spent Hours</b>
        </StyledHeaderCell>
        <StyledHeaderCell>
          <b>Start Date</b>
        </StyledHeaderCell>
        <StyledHeaderCell>
          <b>Due Date</b>
        </StyledHeaderCell>
        <StyledHeaderCell>
          <b>Assigned to</b>
        </StyledHeaderCell>
      </StyledHeaderTableRow>
    );
  };
  return (
    <StyledBox>
      <Table size="small">
        <TableHead>{renderTableHeader()}</TableHead>
        <TableBody>
          {rows.map((task, index) => {
            return (
              <CustomRow
                task={task}
                index={index}
                users={mapUsersData(task, options)}
              />
            );
          })}
        </TableBody>
      </Table>
    </StyledBox>
  );
};

export default CustomTableBody;
