import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import "./clients-list.css";
import axios from "axios";
import Swal from "sweetalert2";
import Spinner from "../Spinner";
import Modal from "react-modal";
import Pagination from "react-js-pagination";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    height: "360px",
    width: "470px",
    padding: "0px",
    overflow: "hidden",
  },
};
export const ClientList = () => {
  const [first_name, setFirstName] = useState("");
  const [client_id, setClient_id] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [company, setCompany] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [clientsData, setClientsData] = useState([]);
  const [modalEditIsOpen, setEditIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const handlePageChange = (currpage) => {
    setLoading(true);
    setPage(currpage);
  };
  let navigate = useNavigate();

  const openModal = () => {
    setFirstName("");
    setCompany("");
    setEmail("");
    setLastName("");
    setPhoneNumber("");
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
  }
  const openEditModal = (id, name, last, email, phn, comp) => {
    setClient_id(id);
    setFirstName(name);
    setCompany(comp);
    setEmail(email);
    setLastName(last);
    setPhoneNumber(phn);

    setEditIsOpen(true);
  };

  function closeEditModal() {
    setEditIsOpen(false);
  }
  const handleDeleteClient = async (client_id) => {
    let result = Swal.fire({
      title: "Are you sure?",
      text: "The deleted client can not be reverted. Do you want to continue?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });
    if ((await result).isConfirmed) {
      setLoading(true);
      await axios.post(`${process.env.REACT_APP_BASE_URL}/api/delete_client`, {
        client_id,
      });

      setRefresh(!refresh);
    }
  };
  const handleEditClient = async () => {
    setLoading(true);
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/edit_client`,
      {
        client_id,
        first_name,
        last_name,
        email,
        phone,
        company,
      } //add service properties
    );

    setFirstName("");
    setCompany("");
    setEmail("");
    setLastName("");
    setPhoneNumber("");

    setEditIsOpen(false);
    setRefresh(!refresh);
  };
  const handleAddClient = async () => {
    setLoading(true);
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/add_client`,
      {
        first_name,
        last_name,
        email,
        phone,
        company,
      } //add service properties
    );

    setFirstName("");
    setCompany("");
    setEmail("");
    setLastName("");
    setPhoneNumber("");
    setIsOpen(false);

    setRefresh(!refresh);
  };

  const getData = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/get_clients`,
      { limit, page }
    );
    setClientsData(response.data);
    setLoading(false);
  };

  useEffect(() => {
    if (localStorage.role === "user") {
      navigate("/");
    }
    getData();
  }, [refresh, page]);
  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        <div
          style={{
            display: "flex",
            background: "grey",
            justifyContent: "space-between",
            padding: "0px",
          }}
        >
          <div
            style={{ marginLeft: "10px", marginTop: "4px", fontWeight: "bold" }}
          >
            {"Add Client"}
          </div>

          <div
            onClick={() => setIsOpen(false)}
            style={{
              marginRight: "5px",
              cursor: "pointer",
            }}
          >
            <img
              src="/assests/img/close-modal.svg"
              width="20"
              alt="modal close"
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "20px" }}>
          <div className="col-md-4 label">Business Name:</div>

          <div className="col-md-7">
            <input
              style={{
                width: "220px",
              }}
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
              type="text"
              name="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "20px" }}>
          <div className=" col-md-4 label">First Name:</div>
          <div className="col-md-7">
            <input
              style={{
                width: "220px",
              }}
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
              // className="shadow-sm"
              type="text"
              name="first_name"
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
        </div>

        <div className="row" style={{ marginLeft: "15px", marginTop: "10px" }}>
          <div className="col-md-4 label">Last Name:</div>
          <div className="col-md-7">
            <input
              style={{
                width: "220px",
              }}
              // className="shadow-sm"
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
              type="text"
              name="last_name"
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "10px" }}>
          <div className="col-md-4 label">Email:</div>

          <div className="col-md-7">
            <input
              style={{
                width: "220px",
              }}
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "10px" }}>
          <div className="col-md-4 label">Phone:</div>

          <div className="col-md-7">
            <input
              style={{
                width: "220px",
              }}
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
              type="phone"
              name="tel"
              value={phone}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
        </div>

        <button
          type="btn"
          className="all-modal-button btn btn-primary"
          style={{ float: "right", marginRight: "65px", marginTop: "28px" }}
          onClick={() => handleAddClient()}
        >
          Save
        </button>
      </Modal>
      <Modal
        isOpen={modalEditIsOpen}
        onRequestClose={closeEditModal}
        style={customStyles}
        ariaHideApp={false}
      >
        <div
          style={{
            display: "flex",
            background: "grey",
            justifyContent: "space-between",
            padding: "0px",
          }}
        >
          <div
            style={{ marginLeft: "10px", marginTop: "4px", fontWeight: "bold" }}
          >
            {"Edit Client"}
          </div>

          <div
            onClick={() => setEditIsOpen(false)}
            style={{
              marginRight: "5px",

              cursor: "pointer",
            }}
          >
            <img
              src="/assests/img/close-modal.svg"
              width="20"
              alt="modal close"
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "20px" }}>
          <div className="col-md-4 label">Business Name:</div>

          <div className="col-md-7">
            <input
              style={{
                width: "220px",
              }}
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
              type="text"
              name="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "20px" }}>
          <div className=" col-md-4 label">First Name:</div>
          <div className="col-md-7">
            <input
              style={{
                width: "220px",
              }}
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
              // className="shadow-sm"
              type="text"
              name="first_name"
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "10px" }}>
          <div className="col-md-4 label">Last Name:</div>
          <div className="col-md-7">
            <input
              style={{
                width: "220px",
              }}
              // className="shadow-sm"
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
              type="text"
              name="last_name"
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "10px" }}>
          <div className="col-md-4 label">Email:</div>

          <div className="col-md-7">
            <input
              style={{
                width: "220px",
              }}
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "10px" }}>
          <div className="col-md-4 label">Phone:</div>

          <div className="col-md-7">
            <input
              style={{
                width: "220px",
              }}
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
              type="phone"
              name="tel"
              value={phone}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
        </div>
        <button
          type="btn"
          className="all-modal-button btn btn-primary"
          style={{ float: "right", marginRight: "65px", marginTop: "28px" }}
          onClick={() => handleEditClient()}
        >
          Save
        </button>
      </Modal>
      <div className="client_list">
        <div className="container client_list-Block">
          <div className="client_list-header shadow-sm">
            <div className="client_list-heading">
              <div className="row">
                <h1 style={{ width: "8%" }}>Clients</h1>
                <div className="add w-auto">
                  <p onClick={() => openModal()} style={{ cursor: "pointer" }}>
                    +Add Client
                  </p>
                </div>
              </div>
            </div>
            <div className="container bg-white shadow-sm inside-table">
              <div className="row projects-block">
                <div className="col-md-12">
                  <div className="client_lists-list-block">
                    <table className="table shadow-sm">
                      <thead>
                        <tr>
                          <th className="table-client_list-heading">
                            Account Manager
                          </th>
                          <th className="table-client_list-heading">Client</th>
                          <th className="table-client_list-heading">Quotes</th>
                          <th className="table-client_list-heading">
                            Projects
                          </th>
                          <th className="table-client_list-heading"></th>
                          <th className="table-client_list-heading"></th>
                          {/* <th className="table-client_list-heading"></th> */}
                        </tr>
                      </thead>
                      <tbody
                        className="table-group-divider"
                        // onClick={() => navigate("/client-page")}
                        style={{ cursor: "pointer" }}
                      >
                        {clientsData.data?.map((client, index) => {
                          return (
                            <tr key={index}>
                              <td
                                style={{ minWidth: "120px" }}
                                onClick={() =>
                                  navigate(`/client-page/${client.id}`)
                                }
                              >
                                <span className="cc">
                                  {client.account_mamanger[0].first_name}{" "}
                                  {client.account_mamanger[0].last_name}
                                </span>
                              </td>
                              <td
                                onClick={() =>
                                  navigate(`/client-page/${client.id}`)
                                }
                              >
                                {client.company}
                              </td>
                              <td
                                onClick={() =>
                                  navigate(`/client-page/${client.id}`)
                                }
                              >
                                $ {""}
                                {Number(client.total_amount).toLocaleString()}
                              </td>
                              <td
                                onClick={() =>
                                  navigate(`/client-page/${client.id}`)
                                }
                              >
                                {client.projects.map((project) => (
                                  <span className="eccomerce" key={project.id}>
                                    {project.project_name}{" "}
                                  </span>
                                ))}
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  openEditModal(
                                    client?.id,
                                    client.first_name,
                                    client.last_name,
                                    client.email,
                                    client.phone,
                                    client.company
                                  )
                                }
                              >
                                Edit
                              </td>
                              <td>
                                <img
                                  onClick={() => handleDeleteClient(client.id)}
                                  style={{ cursor: "pointer" }}
                                  src="/assests/img/close.svg"
                                  width="20"
                                  alt="close"
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "30px",
                      marginRight: "50px",
                    }}
                  >
                    {" "}
                    <footer>
                      <Pagination
                        activePage={page}
                        itemsCountPerPage={limit}
                        totalItemsCount={clientsData?.count}
                        pageRangeDisplayed={5}
                        onChange={(page) => handlePageChange(page)}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </footer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container copy-writes">
          <footer className="row d-flex flex-wrap justify-content-between align-items-center my-4">
            <div className="col-md-4">
              <a
                href="/"
                className="footer-icon mb-3 me-2 mb-md-0 text-muted text-decoration-none"
              >
                <img src="assests/img/footer-icon.svg" alt="" height="15" />
              </a>
              <span className="text-muted">BusinessNET MarketingEngine</span>
              <br />
              <span className="text-muted1">Version 1.0</span>
            </div>

            <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
              <li className="ms-3">
                Privacy policy. Terms of Use. Copyright © 2022 BusinessNET.
              </li>
            </ul>
          </footer>
        </div>
      </div>
    </>
  );
};
