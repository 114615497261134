import React, { useContext, useState, useEffect } from "react";
import "./task-page.css";
import Timer from "./Timer";
import { useParams } from "react-router";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import Spinner from "../Spinner";
import Pagination from "react-js-pagination";
import { AppContext } from "../../contexts/AppContext";
import Parser from "html-react-parser";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useQuill } from "react-quilljs";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import "quill/dist/quill.snow.css";
export const TaskPage = () => {
  console.error = (function () {
    var error = console.error;

    return function (exception) {
      if (
        (exception + "").indexOf(
          "Warning: A component is `contentEditable`"
        ) !== 0
      ) {
        error.apply(console, arguments);
      }
    };
  })();
  const { timerloading, spent_time, timerclose, setSpentTime } =
    useContext(AppContext);
  const { task_id } = useParams();
  const [editorHtml, setEditorHtml] = useState();
  const [taskDetails, setTaskDetails] = useState([]);
  const [taskUpdates, setTaskUpdates] = useState([]);
  const [fullLoading, setfullLoading] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingupdate, setLoadingupdate] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [refresh1, setRefresh1] = useState(false);
  const [atValues, setAtValues] = useState([]);
  const [page, setPage] = useState(1);
  const [page2, setPage2] = useState(1);
  const [limit, setLimit] = useState(10);

  const [matchedItemList, setMatchedItemList] = useState([]);

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "mention",
    "link",
  ];
  const modules = {
    magicUrl: true,

    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@", "#"],
      source: function (searchTerm, renderItem, mentionChar) {
        let values;
        if (mentionChar === "@" || mentionChar === "#") {
          values = atValues;
        }
        if (searchTerm.length === 0) {
          renderItem(values, searchTerm);
        } else {
          const matches = [];
          for (let i = 0; i < values.length; i++)
            if (
              ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
            )
              matches.push(values[i]);
          renderItem(matches, searchTerm);
        }
      },
    },
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ header: [1, 2, false] }],
      ["clean"],
      ["link"],
    ],
  };
  const { quill, quillRef, Quill } = useQuill({
    formats,
    modules,
  });
  React.useEffect(() => {
    if (quill) {
      quill.getModule("mention").options.onSelect = (item, insertItem) => {
        insertItem(item);
      };
      quill.on("text-change", (delta, oldDelta, source) => {
        let matchedItems = quill?.editor.delta.ops
          .map((match) => {
            return match?.insert?.mention?.id;
          })
          .filter((el) => el !== undefined);
        setMatchedItemList(matchedItems);

        setEditorHtml(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
      });
    }
  }, [quill]);

  if (Quill && !quill) {
    // For execute this line only once.
    const MagicUrl = require("quill-magic-url").default; // Install with 'yarn add quill-magic-url'
    Quill.register("modules/magicUrl", MagicUrl);
  }

  const handleCheckboxlist = async (e, checklist_id) => {
    const { checked } = e.target;

    if (checked === true) {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/select_checklist`,
        { checklist_id, check: 1 }
      );
    } else {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/select_checklist`,
        { checklist_id, check: 0 }
      );
    }
  };
  function timeConvert(n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " " + "h:" + rminutes + " " + "m ";
  }

  const onNewUpdate = async () => {
    if (JSON.stringify(quill.getContents()) === '{"ops":[{"insert":"\\n"}]}') {
      Swal.fire("kindly add an update");
      return;
    } else {
      setLoadingupdate(true);
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/add_task_update`,
        {
          project_task_id: task_id,
          message: editorHtml,
          matchedItemList,
        }
      );
      quill.deleteText(0, quill.getLength());
      setEditorHtml("");
      setRefresh1(!refresh1);
    }
  };
  const getData = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/get_user_task_details`,
      { project_task_id: task_id }
    );
    const filter_users = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/filter_users`
    );
    setAtValues(
      filter_users.data?.users.map((user) => {
        return {
          id: user?.id,
          value: user?.first_name + " " + user?.last_name,
        };
      })
    );
    setTaskDetails(response.data);
    setSpentTime(response.data.spent_minutes);
    timerclose();
    setLoading(false);
    setfullLoading(false);
  };
  const handlePageChange = (currpage) => {
    setPage2(currpage);
  };
  const getTaskUpdates = async () => {
    setLoadingupdate(true);
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/get_task_updates`,
      { project_task_id: task_id, page: page2, limit }
    );

    setTaskUpdates(response.data);
    setLoadingupdate(false);
  };
  useEffect(() => {
    getData();
  }, [refresh, page]);
  useEffect(() => {
    getTaskUpdates();
  }, [refresh1, page2]);

  const handleTaskStatus = async (status) => {
    if (status === "Created") {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/mark_task_complete`,
        {
          project_task_id: task_id,
          status: "Completed",
        }
      );
      setRefresh(!refresh);
    } else if (status === "In Progress") {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/mark_task_complete`,
        {
          project_task_id: task_id,
          status: "Completed",
        }
      );
      setRefresh(!refresh);
    } else {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/mark_task_complete`,
        {
          project_task_id: task_id,
          status: "In Progress",
        }
      );
      setRefresh(!refresh);
    }
  };

  return fullLoading ? (
    <Spinner />
  ) : (
    <div className="task_page">
      <div className="container task_page-Block">
        <div className="task_page-header shadow-sm">
          <div className="task_page-heading">
            <h1>
              {taskDetails?.project?.project_name} &nbsp; &rarr; &nbsp;{" "}
              {taskDetails?.task?.task_name}
            </h1>
          </div>
          <div className="container bg-white">
            <div className="row task_page-detail-section">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-5">
                    <h4>Client Info</h4>
                    <div className="client-info-block shadow-sm">
                      <div className="row">
                        <div className="col6 ">
                          <div style={{ display: "flex" }}>
                            <div className="col-4">Business Name: </div>
                            {taskDetails?.client?.company}
                          </div>
                          <div style={{ display: "flex" }}>
                            <div className="col-4"> Owner Name:</div>{" "}
                            <div>
                              {taskDetails?.client?.first_name} {""}
                              {taskDetails?.client?.last_name}
                            </div>
                          </div>
                          <div style={{ display: "flex" }}>
                            <div className="col-4"> Phone Number: </div>{" "}
                            {taskDetails?.client?.phone}
                          </div>
                          <div style={{ display: "flex" }}>
                            <div className="col-4"> Email: </div>{" "}
                            {taskDetails?.client?.email}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="modify-block">
                      <div className="info-row d-flex">
                        <h3>Project Info</h3>
                        <h3 className="marginleft">
                          Status:{" "}
                          <span
                            style={{
                              color:
                                taskDetails?.status === "Created"
                                  ? "#0b55b0"
                                  : "#50b83d",
                              cursor: "pointer",
                              background:
                                taskDetails?.status === "Created"
                                  ? "white"
                                  : "white",
                            }}
                            type="text"
                          >
                            {taskDetails?.status === "Created"
                              ? "Created"
                              : taskDetails?.status === "Completed"
                              ? "Completed"
                              : "In Progress"}
                          </span>
                        </h3>
                        <h3>
                          <span
                            onClick={() =>
                              handleTaskStatus(taskDetails?.status)
                            }
                            style={{
                              backgroundColor:
                                taskDetails?.status === "Created"
                                  ? "#0b55b0"
                                  : "#50b83d",
                              cursor: "pointer",
                            }}
                          >
                            {taskDetails?.status === "Created"
                              ? "Mark As Completed"
                              : taskDetails?.status === "In Progress"
                              ? "Mark As Completed"
                              : taskDetails?.status === "Completed"
                              ? "Mark As In Progress"
                              : null}
                          </span>
                        </h3>
                      </div>
                    </div>
                    <div className="notes-block">
                      <div className="notes-paragraph-section shadow-sm">
                        <p>
                          <span>
                            {taskDetails?.project?.on_board_information ===
                              null ||
                            taskDetails?.project?.on_board_information ===
                              undefined
                              ? " "
                              : Parser(
                                  taskDetails?.project?.on_board_information
                                )}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row Services-section">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-5">
                    <div className="onboard-block">
                      <h1>Notes</h1>
                      <div
                        className="shadow-sm notes-paragraph-section"
                        style={{
                          padding: "7px",
                          fontSize: "14px",
                          minHeight: "200px",
                          overflow: "auto",
                          wordBreak: "break-word",
                        }}
                      >
                        {taskDetails?.task?.note === null ||
                        taskDetails?.task?.note === undefined
                          ? " "
                          : Parser(taskDetails?.task?.note)}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 checklist-box">
                    <div className="onboard-block">
                      <h1>Checklist</h1>
                      <div
                        className="checklist shadow-sm"
                        style={{ minHeight: "200px" }}
                      >
                        <div className="row">
                          <div className="col-12">
                            {taskDetails?.checklist?.map((checkvalue) => {
                              return (
                                <ul
                                  style={{
                                    margin: "0px",
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }} // className="form-check"
                                  key={checkvalue.id}
                                >
                                  <li
                                    style={{
                                      listStyle: "none",
                                    }}
                                  >
                                    <label
                                      style={{
                                        paddingRight: "4px",
                                      }}
                                      className="form-check-label"
                                    >
                                      <input
                                        style={{
                                          marginLeft: "-25px",
                                        }}
                                        className="form-check-input shadow-sm checkbox"
                                        type="checkbox"
                                        name="checklist"
                                        defaultChecked={checkvalue?.check}
                                        onChange={(e) =>
                                          handleCheckboxlist(e, checkvalue.id)
                                        }
                                      />
                                      <p
                                        style={{
                                          marginTop: "-18px",
                                        }}
                                      >
                                        {checkvalue.list_item}
                                      </p>
                                    </label>
                                  </li>
                                </ul>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {timerloading ? (
              <Spinner />
            ) : (
              <div>
                <div className="row tracker">
                  <div>
                    <Timer project_task_id={task_id} />
                  </div>
                </div>
                <div className="row tracker-bar">
                  <div>Time :</div>
                  <div
                    className="success-bar"
                    style={{
                      display: "flex",
                      marginLeft: "11.5%",
                      marginTop: "-2.6%",
                    }}
                  >
                    {timeConvert(spent_time) ?? 0}&nbsp;
                    <ProgressBar
                      style={{
                        backgroundColor: "#76af43",
                        height: "30px",
                        width: "300px",
                        marginRight: "1%",
                        fontSize: "16px",
                        borderRadius: 0,
                      }}
                      now={
                        (spent_time / 60 / taskDetails?.task?.budgetted_hours) *
                          100 ?? 0
                      }
                    />
                    {taskDetails?.task?.budgetted_hours} hours
                  </div>
                </div>
              </div>
            )}

            <div
              className="row updates-main-block"
              style={{ marginTop: "30px" }}
            >
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12 new-update-box">
                    <div
                      className="updates-block shadow-sm"
                      style={{ border: "#d3d3d3", marginTop: "40px" }}
                    >
                      {" "}
                      <div style={{ background: "white" }}>
                        <h3 style={{ marginLeft: "0" }}>New Update</h3>
                      </div>
                      <div style={{ height: "240px" }}>
                        <div style={{ height: 200 }}>
                          <div ref={quillRef} />
                        </div>
                      </div>
                      <div className="save-btn">
                        <button
                          onClick={() => onNewUpdate()}
                          type="button"
                          className="btn"
                        >
                          Post
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row updates-main-block">
              <div className="col-md-12" style={{ marginTop: "-60px" }}>
                <h3>Updates</h3>
                {isLoadingupdate ? (
                  <Spinner />
                ) : (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="updates-block shadow-sm">
                        <div className="updates-section">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-2">
                                <h4>Date</h4>
                              </div>
                              <div className="col-2">
                                <h4>User</h4>
                              </div>
                              <div className="col-7">
                                <h4>Message</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="updates-paragraph-section">
                          <div className="row">
                            <div className="col-12">
                              {taskUpdates.updates?.map((msg) => {
                                return (
                                  <div className="row" key={msg.id}>
                                    <div
                                      className="col-2"
                                      style={{
                                        marginTop: "1%",
                                      }}
                                    >
                                      {moment
                                        .utc(msg?.created_at)
                                        .format("MM/DD/YYYY")}{" "}
                                    </div>

                                    <div
                                      className="col-2"
                                      style={{
                                        marginTop: "1%",
                                      }}
                                    >
                                      {msg?.user}
                                    </div>
                                    <div
                                      className="col-7"
                                      style={{
                                        textAlign: "justify",
                                        marginTop: "1%",
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      {Parser(msg?.message)}{" "}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            marginTop: "30px",
                            marginRight: "50px",
                          }}
                        >
                          <Pagination
                            activePage={page2 === undefined ? 1 : page2}
                            itemsCountPerPage={limit}
                            totalItemsCount={taskUpdates?.count}
                            pageRangeDisplayed={5}
                            onChange={(page) => handlePageChange(page)}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container copy-writes">
        <footer className="row d-flex flex-wrap justify-content-between align-items-center my-4">
          <div className="col-md-4">
            <a
              href="/"
              className="footer-icon mb-3 me-2 mb-md-0 text-muted text-decoration-none"
            >
              <img src="assests/img/footer-icon.svg" alt="" height="15" />
            </a>
            <span className="text-muted">BusinessNET MarketingEngine</span>
            <br />
            <span className="text-muted1">Version 1.0</span>
          </div>

          <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
            <li className="ms-3">
              Privacy policy. Terms of Use. Copyright © 2022 BusinessNET.
            </li>
          </ul>
        </footer>
      </div>
    </div>
  );
};
