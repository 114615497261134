import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import "./quote.css";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "../Spinner";
import Modal from "react-modal";
import ReactQuill, { Quill } from "react-quill";
import Parser from "html-react-parser";
import MagicUrl from "quill-magic-url";
Quill.register("modules/magicUrl", MagicUrl);
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    height: "540px",
    width: "750px",
    padding: "0px",
    overflow: "hidden",
  },
};
const customStyles1 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    height: "550px",
    width: "750px",
    padding: "0px",
    overflow: "hidden",
  },
};
const customStylesProject = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    height: "610px",
    width: "750px",
    padding: "0px",
    overflow: "visible",
  },
};
export const Quote = () => {
  const { quote_id } = useParams();
  const [service_id, setService_id] = useState("");
  const [quoteservice_id, setquoteService_id] = useState("");
  const [details, setDetails] = useState("");
  const [price, setPrice] = useState("");
  const [service_list, setService_List] = useState([]);
  const [title, setTitle] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalEditIsOpen, setEditIsOpen] = useState(false);
  const [modalProjectIsOpen, setProjectIsOpen] = useState(false);
  const [quotesDataService, setQuotesDataServices] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [on_board_information, seton_board_information] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [serviceName, setServiceName] = useState("");
  let navigate = useNavigate();

  const handleDeleteAssignService = async (quote_service_id) => {
    let result = Swal.fire({
      title: "Are you sure?",
      text: "The assigned service can not be reverted. Do you want to continue?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });
    if ((await result).isConfirmed) {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/delete_quote_service`,
        { quote_service_id }
      );

      setRefresh(!refresh);
    }
  };
  const openModal = () => {
    setDetails("");
    setPrice("");
    setService_id("");
    setIsOpen(true);
  };

  function closeModal() {
    setService_id("");
    setIsOpen(false);
  }

  const openEditModal = (id, s_id, pri, detls, s_name) => {
    setServiceName(s_name);
    setPrice(pri);
    setDetails(detls);
    setService_id(s_id);
    setquoteService_id(id);
    setEditIsOpen(true);
  };

  function closeEditModal() {
    setEditIsOpen(false);
  }
  const openProjectModal = () => {
    setTitle(quotesDataService.quote.title);
    setProjectIsOpen(true);
  };

  const closeProjectModal = () => {
    setProjectIsOpen(false);
  };
  const setDateValue = (date) => {
    setStartDate(date);
    setEndDate(date);
  };
  const handleAssignService = async () => {
    setLoading(true);
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/add_quote_service`,
      {
        quote_id,
        service_id,
        details,
        price,
      } //add service properties
    );

    setService_id("");
    setPrice("");
    setDetails("");
    setIsOpen(false);
    setRefresh(!refresh);
    setIsOpen(false);
  };
  const handleEditAssignService = async () => {
    setLoading(true);
    let quote_service_id = quoteservice_id;
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/edit_quote_service`,
      {
        quote_service_id,
        quote_id,
        service_id,
        details,
        price,
      } //add service properties
    );

    setquoteService_id("");
    setService_id("");
    setPrice("");
    setDetails("");
    setServiceName("");
    setEditIsOpen(false);

    setRefresh(!refresh);
    setIsOpen(false);
  };
  const getData = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/get_quote_services`,
      {
        quote_id,
        limit,
        page,
      }
    );
    const get_services = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/get_all_services`
    );
    setService_List(get_services.data);

    setQuotesDataServices(response.data);

    setLoading(false);
  };

  useEffect(() => {
    if (localStorage.role === "user") {
      navigate("/");
    }
    getData();
  }, [refresh]);

  const handleService = (e) => {
    setService_id(e.target.value);
    let priceValue = service_list.data?.find(
      (name) => name.id === parseInt(e.target.value)
    );
    if (priceValue?.recommended_value) {
      setPrice(priceValue?.recommended_value);
    }
  };

  const handleConvertProject = async () => {
    setLoading(true);
    let start_date = moment(startDate).format("YYYY-MM-DD");
    let due_date = moment(endDate).format("YYYY-MM-DD");
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/convert_to_project`,
      {
        quote_id,
        title,
        start_date,
        due_date,
        on_board_information,
      } //add service properties
    );
    setProjectIsOpen(false);
    if (response?.data) {
      navigate(`/project-page/${response.data?.project_id}`);
    }
  };

  const handleQuoteEditorChange = (html) => {
    setDetails(html);
  };
  const handleEditQuoteEditorChange = (html) => {
    setDetails(html);
  };
  const handleProjectEditorChange = (html) => {
    seton_board_information(html);
  };
  const modules = {
    magicUrl: true,
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ header: [1, 2, false] }],
      ["clean"],
      ["link"],
    ],
  };
  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        <div
          style={{
            display: "flex",
            background: "grey",
            justifyContent: "space-between",
            padding: "0px",
          }}
        >
          <div
            style={{ marginLeft: "10px", marginTop: "4px", fontWeight: "bold" }}
          >
            {"Add Quote"}
          </div>

          <div
            onClick={() => setIsOpen(false)}
            style={{
              marginRight: "5px",

              cursor: "pointer",
            }}
          >
            <img
              src="/assests/img/close-modal.svg"
              width="20"
              alt="modal close"
            />
          </div>
        </div>

        <div className="row" style={{ marginLeft: "15px", marginTop: "30px" }}>
          <div className="col-md-3 label">Service :</div>
          <div className="col-md-7">
            <select
              style={{ width: "220px" }}
              name="service_id"
              value={service_id}
              onChange={(e) => handleService(e)}
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
            >
              <option>Select</option>
              {service_list.data?.map((name, i) => {
                return (
                  <option key={i} value={name.id}>
                    {name.service_name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "20px" }}>
          <div className="col-md-3 label">Price :</div>
          <div className="col-md-7">
            <input
              style={{ width: "220px" }}
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
              // className="shadow-sm"
              type="number"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "35px" }}>
          <div className=" col-md-3 label">Details:</div>
          <div className="col-md-8">
            <ReactQuill
              style={{ height: "200px", background: "white" }}
              value={details}
              modules={modules}
              onChange={handleQuoteEditorChange}
            />
          </div>
        </div>
        <button
          type="btn"
          className="all-modal-button btn btn-primary"
          style={{ float: "right", marginRight: "75px", marginTop: "75px" }}
          onClick={() => handleAssignService()}
        >
          Save
        </button>
      </Modal>
      <Modal
        isOpen={modalEditIsOpen}
        onRequestClose={closeEditModal}
        style={customStyles1}
        ariaHideApp={false}
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        <div
          style={{
            display: "flex",
            background: "grey",
            justifyContent: "space-between",
            padding: "0px",
          }}
        >
          <div
            style={{ marginLeft: "10px", marginTop: "4px", fontWeight: "bold" }}
          >
            {"Edit Agency Service"}
          </div>

          <div
            onClick={() => setEditIsOpen(false)}
            style={{
              marginRight: "5px",

              cursor: "pointer",
            }}
          >
            <img
              src="/assests/img/close-modal.svg"
              width="20"
              alt="modal close"
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "30px" }}>
          <div className="col-md-3 label">Service Name :</div>
          <div className="col-md-7">{serviceName}</div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "30px" }}>
          <div className="col-md-3 label">Price :</div>
          <div className="col-md-7">
            <input
              style={{ width: "220px" }}
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
              // className="shadow-sm"
              type="number"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "35px" }}>
          <div className=" col-md-3 label">Details:</div>
          <div className="col-md-8">
            <ReactQuill
              style={{ height: "200px", background: "white" }}
              value={details}
              modules={modules}
              onChange={handleEditQuoteEditorChange}
            />
          </div>
        </div>
        <button
          type="btn"
          className="all-modal-button btn btn-primary"
          style={{ float: "right", marginRight: "75px", marginTop: "75px" }}
          onClick={() => handleEditAssignService()}
        >
          Save
        </button>
      </Modal>

      <Modal
        isOpen={modalProjectIsOpen}
        onRequestClose={closeProjectModal}
        style={customStylesProject}
        ariaHideApp={false}
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        <div
          style={{
            display: "flex",
            background: "grey",
            justifyContent: "space-between",
            padding: "0px",
          }}
        >
          <div
            style={{ marginLeft: "10px", marginTop: "4px", fontWeight: "bold" }}
          >
            {"Convert to Project"}
          </div>

          <div
            onClick={() => setProjectIsOpen(false)}
            style={{
              marginRight: "5px",

              cursor: "pointer",
            }}
          >
            <img
              src="/assests/img/close-modal.svg"
              width="20"
              alt="modal close"
            />
          </div>
        </div>

        <div className="row" style={{ marginLeft: "15px", marginTop: "30px" }}>
          <div className="col-md-3 label">Project :</div>
          <div className="col-md-7">
            <input
              style={{ width: "220px" }}
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
              // className="shadow-sm"
              type="text"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "30px" }}>
          <div className="col-md-3 label">Start Date:</div>
          <div className="col-md-7">
            <DatePicker
              style={{ width: "220px", border: "1px solid" }}
              selected={startDate}
              onChange={(date) => setDateValue(date)}
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "30px" }}>
          <div className="col-md-3 label">Due Date :</div>
          <div className="col-md-7">
            <DatePicker
              style={{ width: "220px" }}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "35px" }}>
          <div className=" col-md-3 label">OnBoard Info:</div>
          <div className="col-md-8">
            <ReactQuill
              style={{ height: "200px", background: "white" }}
              value={on_board_information}
              modules={modules}
              onChange={handleProjectEditorChange}
            />
          </div>
        </div>
        <button
          type="btn"
          className="all-modal-button btn btn-primary"
          style={{ float: "right", marginRight: "75px", marginTop: "75px" }}
          onClick={() => handleConvertProject()}
        >
          Continue
        </button>
      </Modal>
      <div className="quote">
        <div className="container quote-Block">
          <div className="quote-header shadow-sm">
            <div className="quote-heading">
              <h1>
                Quotes &nbsp; &rarr; &nbsp; {quotesDataService.quote?.title}
              </h1>
            </div>
            <div className="container bg-white">
              <div className="row quote-detail-section">
                <div className="col-md-8">
                  <h4>Client Info</h4>
                  <div className="client-info-block shadow-sm">
                    <div className="row">
                      <div className="col6 ">
                        <div style={{ display: "flex" }}>
                          <div className="col-4">Business Name: </div>
                          {quotesDataService.client?.company}
                        </div>
                        <div style={{ display: "flex" }}>
                          <div className="col-4"> Owner Name:</div>{" "}
                          <div>
                            {quotesDataService.client?.first_name} {""}
                            {quotesDataService.client?.last_name}
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div className="col-4"> Phone Number: </div>{" "}
                          {quotesDataService.client?.phone}
                        </div>
                        <div style={{ display: "flex" }}>
                          <div className="col-4"> Email: </div>{" "}
                          {quotesDataService.client?.email}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div className="notes-block">
                    <div
                      className="notes-paragraph-section"
                      style={{ minWidth: "120px" }}
                    >
                      <p>
                        Account Manager:{" "}
                        <span className="cc">
                          {quotesDataService.manager?.first_name}{" "}
                          {quotesDataService.manager?.last_name}
                        </span>
                      </p>
                      <p>
                        Date:{" "}
                        <span>
                          {" "}
                          {moment
                            .utc(quotesDataService.quote?.created_at)
                            .format("MM/DD/YYYY")}
                        </span>
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="row invoice-quote-block">
                <div className="col-md-11">
                  <div className="quotes-list-block">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="table-quote-heading">
                            Quotes Details
                          </th>
                          <th>
                            {quotesDataService?.quote?.status === null ||
                            quotesDataService?.quote?.status === "loss" ? (
                              <div
                                onClick={() => openModal()}
                                style={{
                                  cursor: "pointer",
                                  background: "#0b55b0",
                                  color: "#fff",
                                  fontFamily: "SF-Compact-Display-Regular",
                                  width: "150px",
                                  float: "right",
                                  fontSize: "14px",
                                  padding: "5px",
                                  textAlign: "center",
                                }}
                              >
                                + Add Service
                              </div>
                            ) : null}{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table-group-divider">
                        <tr>
                          <td className="projecct-name">
                            Quote: <span>{quotesDataService.quote?.title}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row projects-block">
                <div className="col-md-12">
                  <div className="quotes-list-block">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="table-quote-heading">
                            Agency Service
                          </th>
                          <th className="table-quote-heading">Details</th>

                          <th className="table-quote-heading quote-price">
                            Price <span>(Ex GST)</span>
                          </th>
                          <th className="table-quote-heading">Repeat</th>
                          <th className="table-quote-heading"></th>
                          <th className="table-quote-heading"></th>
                        </tr>
                      </thead>
                      <tbody className="table-group-divider">
                        {quotesDataService.data?.map((service_data, i) => {
                          return (
                            <tr key={i}>
                              <td>{service_data?.service_name}</td>
                              <td className="quote-details">
                                {service_data?.details === null ||
                                service_data?.details === undefined
                                  ? ""
                                  : Parser(service_data?.details)}
                              </td>

                              <td className="quote-price">
                                $ {Number(service_data?.price).toLocaleString()}
                              </td>
                              <td>
                                {service_data?.repeat === 0 ? "False" : "True"}
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  openEditModal(
                                    service_data?.id,
                                    service_data?.service_id,
                                    service_data?.price,
                                    service_data?.details,
                                    service_data?.service_name
                                  )
                                }
                              >
                                Edit
                              </td>
                              <td>
                                <img
                                  onClick={() =>
                                    handleDeleteAssignService(service_data.id)
                                  }
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "40%",
                                  }}
                                  src="/assests/img/close.svg"
                                  width="20"
                                  alt="close"
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="row invoice-cal">
                      <div
                        className="col-7"
                        style={{ marginRight: "-6%" }}
                      ></div>
                      <div className="col-4 subtotal">
                        <div className="row">
                          <div className="col-6">Subtotal</div>
                          <div className="col-6">
                            <span>
                              {" "}
                              ${" "}
                              {Number(
                                quotesDataService?.sub_total
                              ).toLocaleString()}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-3"></div>
                    </div>
                    <div className="row invoice-cal">
                      <div className="col-7" style={{ marginRight: "-6%" }}>
                        {/* <p className="add">+Assign Service</p> */}
                      </div>
                      <div className="col-4 tax">
                        <div className="row">
                          <div className="col-6">Tax (10%)</div>
                          <div className="col-6">
                            <span>
                              ${" "}
                              {Number(quotesDataService?.tax).toLocaleString()}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-3"></div>
                    </div>
                    <div className="row invoice-cal">
                      <div
                        className="col-7"
                        style={{ marginRight: "-6%" }}
                      ></div>
                      <div className="col-4 total">
                        <div className="row">
                          <div className="col-6 table-quote-heading">
                            Total (USD)
                          </div>
                          <div className="col-6">
                            <span>
                              $
                              {Number(
                                quotesDataService?.total_price
                              ).toLocaleString()}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-3"></div>
                    </div>
                  </div>
                  {quotesDataService?.quote?.status === null ||
                  quotesDataService?.quote?.status === "loss" ? (
                    <div
                      // className="row convert-text-block"
                      style={{
                        cursor: "pointer",
                        background: "#0b55b0",
                        color: "#fff",
                        fontFamily: "SF-Compact-Display-Regular",
                        width: "125px",
                        fontSize: "14px",
                        marginBottom: "30px",
                        paddingLeft: "8px",
                        paddingTop: "2px",
                        paddingBottom: "2px",
                      }}
                      onClick={() => openProjectModal()}
                    >
                      Convert to Project
                    </div>
                  ) : (
                    <button className="btn btn-success m-3">
                      Converted To project
                    </button>
                  )}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container copy-writes">
          <footer className="row d-flex flex-wrap justify-content-between align-items-center my-4">
            <div className="col-md-4">
              <a
                href="/"
                className="footer-icon mb-3 me-2 mb-md-0 text-muted text-decoration-none"
              >
                <img src="assests/img/footer-icon.svg" alt="" height="15" />
              </a>
              <span className="text-muted">BusinessNET MarketingEngine</span>
              <br />
              <span className="text-muted1">Version 1.0</span>
            </div>

            <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
              <li className="ms-3">
                Privacy policy. Terms of Use. Copyright © 2022 BusinessNET.
              </li>
            </ul>
          </footer>
        </div>
      </div>
    </>
  );
};
