import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./project-page.css";
import TasksTable from "../../components/tasksTable";
import axios from "axios";
import Spinner from "../Spinner";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import moment from "moment/moment";
import Parser from "html-react-parser";
import Pagination from "react-js-pagination";
import DynamicCollapsibleTable from "../../components/dynamicCollapsible";
import { Multiselect } from "multiselect-react-dropdown";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useQuill } from "react-quilljs";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

const customStylesLink = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    height: "460px",
    width: "525px",
    padding: "0px",
    overflow: "hidden",
  },
};
const customStylesFile = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    height: "370px",
    width: "525px",
    padding: "0px",
    overflow: "hidden",
  },
};
const customStylesProjectService = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    height: "540px",
    width: "750px",
    padding: "0px",
    overflow: "hidden",
  },
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    height: "350px",
    width: "400px",
    padding: "0px",
    overflow: "visible",
  },
};
export const ProjectPage = () => {
  console.error = (function () {
    var error = console.error;

    return function (exception) {
      if (
        (exception + "").indexOf(
          "Warning: A component is `contentEditable`"
        ) !== 0
      ) {
        error.apply(console, arguments);
      }
    };
  })();
  console.log = console.warn = console.error = () => {};
  const { project_id } = useParams();
  const [editorHtml, setEditorHtml] = useState("");
  const [fullLoading, setfullLoading] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingupdate, setLoadingupdate] = useState(true);
  const [isLoadingLink, setLoadingLink] = useState(true);
  const [rows, setRows] = useState([]);
  const [tasksRows, setTasksRows] = useState([]);
  const [ProjectTasks, setProjectTasks] = useState([]);
  const [task_id, setTaskId] = useState("");
  const [service_id, setService_id] = useState("");
  const [start_date, setStart_date] = useState("");
  const [projectServices, setProjectServices] = useState([]);
  const [due_date, setDue_date] = useState("");
  const [task_Names, setTaskNames] = useState("");
  const [url, setUrl] = useState("");
  const [file, setFile] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalLinkIsOpen, setLinkIsOpen] = useState(false);
  const [modalUploadIsOpen, setUploadIsOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [refresh1, setRefresh1] = useState(false);
  const [refresh2, setRefresh2] = useState(false);
  const [users, setUsers] = useState([]);
  const [userProjectDetails, setuserProjectDetails] = useState([]);
  const [linksuploadsData, setlinksuploadsData] = useState([]);
  const [updatesData, setupdatesData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter_type, setFilter_type] = useState([]);
  const [user_ids, setUser_ids] = useState([]);
  const [all_users, setAll_users] = useState([]);
  const [isLoadingFilter, setLoadingFilter] = useState(false);
  const [atValues, setAtValues] = useState([]);
  const [linkName, setLinkName] = useState("");
  const [uploadName, setUploadName] = useState("");
  const [matchedItemList, setMatchedItemList] = useState([]);
  const [modalProectServiceIsOpen, setProjectServiceIsOpen] = useState(false);
  const [details, setDetails] = useState("");
  const [price, setPrice] = useState("");
  const [service_list, setService_List] = useState([]);

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "mention",
    "link",
  ];
  const modules = {
    magicUrl: true,
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@"],
      dataAttributes: [
        "id",
        "value",
        "denotationChar",
        "link",
        "target",
        "disabled",
      ],
      source: function (searchTerm, renderItem, mentionChar) {
        let values;
        if (mentionChar === "@") {
          values = atValues;
        }
        if (searchTerm.length === 0) {
          renderItem(values, searchTerm);
        } else {
          const matches = [];
          for (let i = 0; i < values.length; i++)
            if (
              ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
            )
              matches.push(values[i]);

          renderItem(matches, searchTerm);
        }
      },
    },
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ header: [1, 2, false] }],
      ["clean"],
      ["link"],
    ],
  };
  const { quill, quillRef, Quill } = useQuill({
    formats,
    modules,
  });
  useEffect(() => {
    if (quill) {
      quill.getModule("mention").options.onSelect = (item, insertItem) => {
        const cursorPos = quill.getSelection();
        insertItem(item);
      };
      quill.on("text-change", (delta, oldDelta, source) => {
        let matchedItems = quill?.editor.delta.ops
          .map((match) => {
            return match?.insert?.mention?.id;
          })
          .filter((el) => el !== undefined);
        setMatchedItemList(matchedItems);
        setEditorHtml(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
      });
    }
  }, [quill]);
  if (Quill && !quill) {
    // For execute this line only once.
    const MagicUrl = require("quill-magic-url").default; // Install with 'yarn add quill-magic-url'
    Quill.register("modules/magicUrl", MagicUrl);
  }

  useEffect(() => {
    handleServiceRequest();
  }, [refresh, filter_type, user_ids,project_id]);
  useEffect(() => {
    handleLinkUploadRequest();
  }, [refresh2,project_id]);
  useEffect(() => {
    setLoadingupdate(true);
    handlelinksUpload();
    setLoadingupdate(false);
  }, [refresh1,project_id]);
  const onNewUpdate = async () => {
    if (JSON.stringify(quill.getContents()) === '{"ops":[{"insert":"\\n"}]}') {
      Swal.fire("kindly add an update");
      return;
    } else {
      setLoadingupdate(true);
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/add_project_update`,
        {
          project_id,
          message: editorHtml,
          matchedItemList,
        }
      );
      quill.deleteText(0, quill.getLength());
      setLoadingupdate(false);
      setEditorHtml("");
      setRefresh1(!refresh1);
    }
  };

  const handleSelectedUser = async (data, item, taskId) => {
    await axios.post(`${process.env.REACT_APP_BASE_URL}/api/assign_user_task`, {
      user_id: item.id,
      project_task_id: taskId,
      action: "allocate_task",
    });
  };
  const handleDeSelectedUser = async (data, item, taskId) => {
    await axios.post(`${process.env.REACT_APP_BASE_URL}/api/assign_user_task`, {
      user_id: item.id,
      project_task_id: taskId,
      action: "deallocate_task",
    });
  };

  const handleServiceRequest = async () => {
    setLoading(true);
    const tag_users = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/filter_users`
    );
    const users = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/get_project_users`,
      { project_id }
    );

    let data = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/get_project_services_details`,
      {
        project_id,
        filter_type,
        user_ids,
      }
    );
    setAll_users(
      users.data?.data?.map((user) => {
        return {
          id: user?.id,
          name: user?.first_name + " " + user?.last_name,
        };
      })
    );
    setAtValues(
      tag_users.data?.users.map((user) => {
        return {
          id: user?.id,
          value: user?.first_name + " " + user?.last_name,
        };
      })
    );
    let data2 = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/get_user_project_details`,
      {
        project_id,
      }
    );

    const response2 = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/get_skill_users`
    );

    setProjectTasks(data.data);
    setuserProjectDetails(data2?.data);
    setUsers(response2.data.data);
    setTasksRows(data.data.tasks);
    setRows(data.data.services);
    setLoading(false);
    setLoadingFilter(false);
    setfullLoading(false);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/get_project_services`, {
        project_id,
      })
      .then((data) => setProjectServices(data?.data));

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/get_project_additional_services`,
        {
          project_id,
        }
      )
      .then((data) => setService_List(data?.data));

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/get_project_additional_tasks`,
        {
          project_id,
        }
      )
      .then((data) => setTaskNames(data.data));
  };

  const handleLinkUploadRequest = async () => {
    let linkuploadData = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/get_project_links_uploads`,
      {
        project_id,
      }
    );

    setlinksuploadsData(linkuploadData.data);

    setLoadingLink(false);
  };

  const handlelinksUpload = async (n_page) => {
    setLoadingupdate(true);
    setPage(n_page);
    let data3 = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/get_project_updates`,
      {
        project_id,
        page: n_page,
      }
    );

    setupdatesData(data3.data);

    setLoadingupdate(false);
  };

  const columns = [
    { key: "Service", name: "service_name" },
    { key: "Status", name: "status" },
    { key: "Spent hours", name: "spent_hours" },
    { key: "Start date", name: "start_date" },
    { key: "Due date", name: "due_date" },
    { key: "Assigned to", name: "assigned_to" },
  ];
  const taskColumns = [
    { key: "Task", name: "task_name" },
    { key: "Status", name: "status" },
    { key: "Spent hours", name: "spent_hours" },
    { key: "Start date", name: "start_date" },
    { key: "Due date", name: "due_date" },
    { key: "Assigned to", name: "assigned_to" },
  ];

  const handleSelectFilterttype = async (data, item) => {
    if (item.id === undefined) {
      setFilter_type([]);
      setLoadingFilter(true);
    } else {
      setFilter_type([item.id]);
      setLoadingFilter(true);
    }
  };
  const handleSelectFilter = (data, item) => {
    setUser_ids([...user_ids, item.id]);
    setLoadingFilter(true);
  };
  const handleDeSelectedFilter = (data, item) => {
    let updatedids = user_ids.filter((e) => e !== item.id);
    setUser_ids(updatedids);
    setLoadingFilter(true);
  };
  const openModal = () => {
    setIsOpen(true);
  };
  function closeModal() {
    setDue_date("");
    setStart_date("");
    setTaskId("");
    setIsOpen(false);
  }
  const openLinkModal = () => {
    setLinkIsOpen(true);
  };
  function closeLinkModal() {
    setService_id("");
    setUrl("");
    setLinkIsOpen(false);
  }
  const openUploadModal = () => {
    setUploadIsOpen(true);
  };
  function closeUploadModal() {
    setService_id("");
    setFile();
    setUploadIsOpen(false);
  }

  const openProjectServiceModal = () => {
    setProjectServiceIsOpen(true);
  };
  function closeProjectServiceModal() {
    setService_id("");
    setProjectServiceIsOpen(false);
  }

  const handleAddTask = async () => {
    if (task_id === "" || start_date === "" || due_date === "") {
      Swal.fire({
        title: "All fields are required",
        icon: "warning",
        confirmButtonColor: "#d33",
      });
    } else {
      setLoading(true);
      closeModal();
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/add_project_task`,
        {
          project_id,
          task_id,
          start_date: moment(start_date).format("YYYY-MM-DD"),
          due_date: moment(due_date).format("YYYY-MM-DD"),
        }
      );
      setRefresh(!refresh);
      setLoading(false);
    }
  };

  const handleLink = async () => {
    if (service_id === " " || url === "") {
      return;
    }
    setLoadingLink(true);
    setLinkIsOpen(false);

    await axios.post(`${process.env.REACT_APP_BASE_URL}/api/add_project_link`, {
      project_id,
      service_id,
      url,
      name: linkName,
    });
    setService_id("");
    setUrl("");

    setRefresh2(!refresh2);
  };
  const handleUpload = async () => {
    if (service_id === " " || file === "") {
      return;
    }
    setLoadingLink(true);
    setUploadIsOpen(false);

    const url = `${process.env.REACT_APP_BASE_URL}/api/add_project_upload`;

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    await axios.post(
      url,
      { service_id, project_id, file, name: uploadName },
      config
    );

    setService_id("");
    setFile(null);
    setRefresh2(!refresh2);
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };
  const handleService = (e) => {
    setService_id(e.target.value);
    let priceValue = service_list.data?.find(
      (name) => name.id === parseInt(e.target.value)
    );
    if (priceValue?.recommended_value) {
      setPrice(priceValue?.recommended_value);
    }
  };

  const handleQuoteEditorChange = (html) => {
    setDetails(html);
  };

  const modules2 = {
    magicUrl: true,
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ header: [1, 2, false] }],
      ["clean"],
      ["link"],
    ],
  };

  const handleAssignService = async () => {
    if (service_id === "") {
      Swal.fire({
        title: "Service required",
        icon: "warning",
        confirmButtonColor: "#d33",
      });
    } else {
      setLoading(true);
      setProjectServiceIsOpen(false);
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/add_project_service`,
        {
          service_id,
          project_id,
          details,
          price,
        } //add service properties
      );

      setService_id("");
      setPrice("");
      setDetails("");

      setRefresh(!refresh);
    }
  };
  return fullLoading ? (
    <Spinner />
  ) : (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        <div
          style={{
            display: "flex",
            background: "grey",
            justifyContent: "space-between",
            padding: "0px",
          }}
        >
          <div
            style={{ marginLeft: "10px", marginTop: "4px", fontWeight: "bold" }}
          >
            {"Add Task"}
          </div>

          <div
            onClick={() => setIsOpen(false)}
            style={{
              marginRight: "5px",
              cursor: "pointer",
            }}
          >
            <img
              src="/assests/img/close-modal.svg"
              width="20"
              alt="modal close"
            />
          </div>
        </div>

        <div className="row" style={{ marginLeft: "15px", marginTop: "35px" }}>
          <div className=" col-md-4 label">Task:</div>
          <div className="col-md-7">
            <select
              style={{ width: "220px" }}
              name="tasks"
              value={task_id}
              onChange={(e) => setTaskId(e.target.value)}
              className="form-select-sm"
              aria-label=".form-select-sm example"
            >
              <option>Select</option>
              {task_Names.data?.map((name) => {
                return <option value={name.id}>{name.task_name}</option>;
              })}
            </select>
          </div>
        </div>

        <div className="row" style={{ marginLeft: "15px", marginTop: "35px" }}>
          <div className=" col-md-4 label">Start Date:</div>
          <div className="col-md-7">
            <DatePicker
              style={{ width: "220px" }}
              selected={start_date}
              onChange={(d) => setStart_date(d)}
            />
          </div>
        </div>

        <div className="row" style={{ marginLeft: "15px", marginTop: "35px" }}>
          <div className=" col-md-4 label">Due Date:</div>
          <div className="col-md-7">
            <DatePicker
              style={{ width: "220px" }}
              selected={due_date}
              onChange={(d) => setDue_date(d)}
            />
          </div>
        </div>

        <button
          type="btn"
          className="all-modal-button btn btn-primary"
          style={{ float: "right", marginRight: "15px", marginTop: "38px" }}
          onClick={() => handleAddTask()}
        >
          Save
        </button>
      </Modal>
      <Modal
        isOpen={modalLinkIsOpen}
        onRequestClose={closeLinkModal}
        style={customStylesLink}
        ariaHideApp={false}
      >
        <div
          style={{
            display: "flex",
            background: "grey",
            justifyContent: "space-between",
            padding: "0px",
          }}
        >
          <div
            style={{ marginLeft: "10px", marginTop: "4px", fontWeight: "bold" }}
          >
            {"Add Link"}
          </div>

          <div
            onClick={() => setLinkIsOpen(false)}
            style={{
              marginRight: "5px",
              cursor: "pointer",
            }}
          >
            <img
              src="/assests/img/close-modal.svg"
              width="20"
              alt="modal close"
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "35px" }}>
          <div className=" col-md-3 label">Name:</div>
          <div className="col-md-7">
            <input
              style={{ width: "220px" }}
              className="all-input"
              type="text"
              name="link_name"
              onChange={(e) => setLinkName(e.target.value)}
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "35px" }}>
          <div className=" col-md-3 label">Service:</div>
          <div className="col-md-7">
            <select
              style={{ width: "220px" }}
              name="service"
              value={service_id}
              onChange={(e) => setService_id(e.target.value)}
              className="form-select-sm"
              aria-label=".form-select-sm example"
            >
              <option>Select</option>
              {projectServices.data?.map((name) => {
                return (
                  <option value={name.service_id}>{name.service_name}</option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "35px" }}>
          <div className=" col-md-3 label">Link:</div>
          <div className="col-md-7">
            <textarea
              style={{ width: "300px" }}
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
              // className="shadow-sm"
              type="text"
              name="url"
              rows={"6"}
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
        </div>

        <button
          type="btn"
          className="all-modal-button btn btn-primary"
          style={{ float: "right", marginRight: "45px", marginTop: "38px" }}
          onClick={() => handleLink()}
        >
          Save
        </button>
      </Modal>
      <Modal
        isOpen={modalUploadIsOpen}
        onRequestClose={closeUploadModal}
        style={customStylesFile}
        ariaHideApp={false}
      >
        <div
          style={{
            display: "flex",
            background: "grey",
            justifyContent: "space-between",
            padding: "0px",
          }}
        >
          <div
            style={{ marginLeft: "10px", marginTop: "4px", fontWeight: "bold" }}
          >
            {"Add File"}
          </div>

          <div
            onClick={() => setUploadIsOpen(false)}
            style={{
              marginRight: "5px",
              cursor: "pointer",
            }}
          >
            <img
              src="/assests/img/close-modal.svg"
              width="20"
              alt="modal close"
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "35px" }}>
          <div className=" col-md-3 label">Name:</div>
          <div className="col-md-7">
            <input
              style={{ width: "220px" }}
              className="all-input"
              type="text"
              name="upload_name"
              onChange={(e) => setUploadName(e.target.value)}
            />
          </div>
        </div>

        <div className="row" style={{ marginLeft: "15px", marginTop: "35px" }}>
          <div className=" col-md-3 label">Service:</div>
          <div className="col-md-7">
            <select
              style={{ width: "220px" }}
              name="service"
              value={service_id}
              onChange={(e) => setService_id(e.target.value)}
              className="form-select-sm"
              aria-label=".form-select-sm example"
            >
              <option>Select</option>
              {projectServices.data?.map((name) => {
                return (
                  <option value={name.service_id}>{name.service_name}</option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "35px" }}>
          <div className=" col-md-3 label">File:</div>
          <div className="col-md-7">
            <input
              className="all-input form-select-sm"
              type="file"
              name="file"
              onChange={onImageChange}
            />
          </div>
        </div>
        <button
          type="btn"
          className="all-modal-button btn btn-primary"
          style={{ float: "right", marginRight: "45px", marginTop: "38px" }}
          onClick={() => handleUpload()}
        >
          Save
        </button>
      </Modal>
      <Modal
        isOpen={modalProectServiceIsOpen}
        onRequestClose={closeProjectServiceModal}
        style={customStylesProjectService}
        ariaHideApp={false}
      >
        <div
          style={{
            display: "flex",
            background: "grey",
            justifyContent: "space-between",
            padding: "0px",
          }}
        >
          <div
            style={{ marginLeft: "10px", marginTop: "4px", fontWeight: "bold" }}
          >
            {"Add Project Service"}
          </div>

          <div
            onClick={() => setProjectServiceIsOpen(false)}
            style={{
              marginRight: "5px",

              cursor: "pointer",
            }}
          >
            <img
              src="/assests/img/close-modal.svg"
              width="20"
              alt="modal close"
            />
          </div>
        </div>

        <div className="row" style={{ marginLeft: "15px", marginTop: "30px" }}>
          <div className="col-md-3 label">Service :</div>
          <div className="col-md-7">
            <select
              style={{ width: "220px" }}
              name="service_id"
              value={service_id}
              onChange={(e) => handleService(e)}
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
            >
              <option>Select</option>
              {service_list.data?.map((name) => {
                return <option value={name.id}>{name.service_name}</option>;
              })}
            </select>
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "20px" }}>
          <div className="col-md-3 label">Price :</div>
          <div className="col-md-7">
            <input
              style={{ width: "220px" }}
              className="all-input form-select-sm"
              aria-label=".form-select-sm example"
              type="number"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "35px" }}>
          <div className=" col-md-3 label">Details:</div>
          <div className="col-md-8">
            <ReactQuill
              style={{ height: "200px", background: "white" }}
              value={details}
              modules={modules2}
              onChange={handleQuoteEditorChange}
            />
          </div>
        </div>
        <button
          type="btn"
          className="all-modal-button btn btn-primary"
          style={{ float: "right", marginRight: "75px", marginTop: "75px" }}
          onClick={() => handleAssignService()}
        >
          Save
        </button>
      </Modal>
      <div className="project_page" style={{ margin: "0px" }}>
        <div className="container project_page-Block">
          <div className="project_page-header shadow-sm">
            <div className="project_page-heading">
              <h1>
                Projects &nbsp; &rarr; &nbsp;{" "}
                {userProjectDetails?.project?.project_name}
              </h1>
            </div>
            <div className="container bg-white">
              <div className="row project_page-detail-section">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-5">
                      <h4>Client Info</h4>
                      <div className="client-info-block shadow-sm">
                        <div className="row">
                          <div className="col6 ">
                            <div style={{ display: "flex" }}>
                              <div className="col-4">Business Name: </div>
                              {userProjectDetails?.client?.company}
                            </div>
                            <div style={{ display: "flex" }}>
                              <div className="col-4"> Owner Name:</div>{" "}
                              <div>
                                {userProjectDetails?.client?.first_name} {""}
                                {userProjectDetails?.client?.last_name}
                              </div>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div className="col-4"> Phone Number: </div>{" "}
                              {userProjectDetails?.client?.phone}
                            </div>
                            <div style={{ display: "flex" }}>
                              <div className="col-4"> Email: </div>{" "}
                              {userProjectDetails?.client?.email}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="modify-block">
                        <div className="info-row d-flex">
                          <h3>Project Info</h3>
                          <h3 className="marginleft">
                            Status:{" "}
                            <span>
                              {userProjectDetails?.project?.project_status}
                            </span>
                          </h3>
                        </div>
                      </div>
                      <div className="notes-block">
                        <div className="notes-paragraph-section shadow-sm">
                          <p>
                            <span>
                              {userProjectDetails?.project
                                ?.on_board_information === null ||
                              userProjectDetails?.project
                                ?.on_board_information === undefined
                                ? " "
                                : Parser(
                                    userProjectDetails?.project
                                      ?.on_board_information
                                  )}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row filter">
                <div className="col-md-12">
                  <div className="row pt-2">
                    <div
                      className="col-md-1"
                      style={{ maxWidth: "59px", marginTop: "0.8%" }}
                    >
                      Filters:{" "}
                    </div>

                    <div className="col-md-2">
                      <Multiselect
                        options={[
                          {},
                          {
                            id: "only me",
                            key: "Only Mine",
                          },
                          {
                            id: "all",
                            key: "ALL",
                          },
                        ]}
                        onSelect={handleSelectFilterttype} // Function will trigger on select event
                        singleSelect // Function will trigger on remove event
                        displayValue="key"
                        placeholder={"Select"}
                      />
                    </div>
                    <div className="col-md-2">
                      <Multiselect
                        options={all_users}
                        onSelect={handleSelectFilter} // Function will trigger on select event
                        onRemove={handleDeSelectedFilter}
                        displayValue="name"
                        hideSelectedList
                        showCheckbox
                        showArrow
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row projects-block">
                <div className="col-md-12">
                  <div
                    className="project_pages-list-block updates-main-block"
                    style={{ cursor: "pointer" }}
                  >
                    {isLoading || isLoadingFilter ? (
                      <Spinner />
                    ) : (
                      <>
                        <div className="row">
                          <div
                            className="table-client_page-heading col-4"
                            style={{
                              fontSize: "16px",
                              fontFamily: "SF-Compact-Display-Regular",
                              paddingLeft: "1.5%",
                              fontWeight: "600",
                            }}
                          >
                            Services
                          </div>

                          <div
                            className="col-8"
                            style={{ paddingRight: "1.3%" }}
                          >
                            <div
                              className="addtask"
                              onClick={() => openProjectServiceModal()}
                              style={{
                                float: "right",
                                cursor: "pointer",
                                background: "#0b55b0",
                                color: "#fff",
                                fontFamily: "SF-Compact-Display-Regular",
                                width: "13%",
                                fontSize: "14px",
                                padding: "5px",
                                textAlign: "center",
                              }}
                            >
                              +Add Service
                            </div>
                          </div>
                        </div>
                        <DynamicCollapsibleTable
                          data={rows}
                          columns={columns}
                          users={users}
                        />

                        <br />

                        <div className="row">
                          <div
                            className="table-client_page-heading col-4"
                            style={{
                              fontSize: "16px",
                              fontFamily: "SF-Compact-Display-Regular",
                              paddingLeft: "1.5%",
                              fontWeight: "600",
                            }}
                          >
                            Tasks
                          </div>

                          <div
                            className="col-8"
                            style={{ paddingRight: "1.3%" }}
                          >
                            <div
                              className="addtask"
                              onClick={() => openModal()}
                              style={{
                                float: "right",
                                cursor: "pointer",
                                background: "#0b55b0",
                                color: "#fff",
                                fontFamily: "SF-Compact-Display-Regular",
                                width: "11%",
                                fontSize: "14px",
                                padding: "5px",
                                textAlign: "center",
                              }}
                            >
                              +Add Task
                            </div>
                          </div>
                        </div>
                        <TasksTable
                          rows={tasksRows}
                          columns={taskColumns}
                          options={users}
                          handleSelectedUser={handleSelectedUser}
                          handleDeSelectedUser={handleDeSelectedUser}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              {isLoadingLink ? (
                <Spinner />
              ) : (
                <div>
                  <div className="row upload-link-section">
                    <div className="col-md-12">
                      <div className="row link-upload-block">
                        <div className="col-md-12">
                          <div className="row updates-main-block">
                            <div className="col-md-12">
                              <div className="row">
                                <div
                                  className="table-client_page-heading col-4"
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "SF-Compact-Display-Regular",
                                    paddingLeft: "3%",
                                    fontWeight: "600",
                                    marginBottom: "8px",
                                  }}
                                >
                                  Uploads
                                </div>

                                <div
                                  className="col-8"
                                  style={{ paddingRight: "2.6%" }}
                                >
                                  <div
                                    onClick={() => openUploadModal()}
                                    style={{
                                      float: "right",
                                      cursor: "pointer",
                                      background: "#0b55b0",
                                      color: "#fff",
                                      fontFamily: "SF-Compact-Display-Regular",
                                      width: "13.5%",
                                      fontSize: "14px",
                                      padding: "5px",
                                      textAlign: "center",
                                    }}
                                  >
                                    +Add Upload
                                  </div>
                                </div>
                              </div>

                              <div className="updates-block link-updates shadow-sm">
                                <div className="updates-section">
                                  <div className="row">
                                    <div className="col-2">
                                      <h4>Date</h4>
                                    </div>
                                    <div className="col-2">
                                      <h4>Name</h4>
                                    </div>

                                    <div className="col-2">
                                      <h4>Service</h4>
                                    </div>
                                    <div className="col-6">
                                      <h4>Upload</h4>
                                    </div>
                                  </div>
                                </div>
                                <div className="updates-paragraph-section">
                                  <div
                                    className="row"
                                    style={{ padding: "5px" }}
                                  >
                                    {linksuploadsData.uploads?.map(
                                      (msg, index) => {
                                        return (
                                          <div
                                            className="row"
                                            style={{ marginTop: "1%" }}
                                            key={index}
                                          >
                                            <div
                                              className="col-2 "
                                              style={{
                                                overflowWrap: "break-word",
                                                marginTop: "1%",
                                              }}
                                            >
                                              {moment
                                                .utc(msg?.created_at)
                                                .format("MM/DD/YYYY")}{" "}
                                            </div>
                                            <div
                                              className="col-2"
                                              style={{
                                                overflowWrap: "break-word",
                                                marginTop: "1%",
                                              }}
                                            >
                                              {msg?.name}
                                            </div>
                                            <div
                                              className="col-2"
                                              style={{
                                                overflowWrap: "break-word",
                                                marginTop: "1%",
                                              }}
                                            >
                                              {msg?.service_name}
                                            </div>
                                            <div
                                              className="col-6"
                                              style={{
                                                overflowWrap: "break-word",
                                                paddingLeft: "2%",
                                              }}
                                            >
                                              <a
                                                href={
                                                  process.env
                                                    .REACT_APP_BASE_URL +
                                                  "/" +
                                                  msg?.file_path
                                                }
                                                download
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {msg?.original_name}
                                              </a>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row upload-link-section">
                    <div className="col-md-12">
                      <div className="row link-upload-block">
                        <div className="col-md-12">
                          <div className="row updates-main-block">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="row">
                                  <div
                                    className="table-client_page-heading col-4"
                                    style={{
                                      fontSize: "16px",
                                      fontFamily: "SF-Compact-Display-Regular",
                                      paddingLeft: "3%",
                                      marginBottom: "8px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Links
                                  </div>

                                  <div
                                    className="col-8"
                                    style={{ paddingRight: "0.5%" }}
                                  >
                                    <div
                                      onClick={() => openLinkModal()}
                                      style={{
                                        float: "right",
                                        cursor: "pointer",
                                        background: "#0b55b0",
                                        color: "#fff",
                                        fontFamily:
                                          "SF-Compact-Display-Regular",
                                        width: "10%",
                                        fontSize: "14px",
                                        padding: "5px",
                                      }}
                                    >
                                      +Add Link
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="updates-block link-updates shadow-sm">
                                <div className="updates-section">
                                  <div className="row">
                                    <div className="col-2">
                                      <h4>Date</h4>
                                    </div>
                                    <div className="col-2">
                                      <h4>Name</h4>
                                    </div>
                                    <div className="col-2">
                                      <h4>Service</h4>
                                    </div>
                                    <div className="col-6">
                                      <h4>Link</h4>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="updates-paragraph-section"
                                  style={{ padding: "5px" }}
                                >
                                  <div className="row">
                                    {linksuploadsData.links?.map(
                                      (msg, index) => {
                                        return (
                                          <div
                                            className="row"
                                            style={{ marginTop: "1%" }}
                                            key={index}
                                          >
                                            <div
                                              className="col-2"
                                              style={{
                                                overflowWrap: "break-word",
                                                marginTop: "1%",
                                              }}
                                            >
                                              {moment
                                                .utc(msg?.created_at)
                                                .format("MM/DD/YYYY")}{" "}
                                            </div>
                                            <div
                                              className="col-2"
                                              style={{
                                                overflowWrap: "break-word",
                                                marginTop: "1%",
                                              }}
                                            >
                                              {msg?.name}{" "}
                                            </div>
                                            <div
                                              className="col-2"
                                              style={{
                                                overflowWrap: "break-word",
                                                marginTop: "1%",
                                              }}
                                            >
                                              {msg?.service_name}
                                            </div>
                                            <div
                                              className="col-6"
                                              style={{
                                                overflowWrap: "break-word",
                                                paddingLeft: "2%",
                                              }}
                                            >
                                              <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={msg?.url}
                                              >
                                                {msg?.url}
                                              </a>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div
                className="row updates-main-block"
                style={{ marginTop: "30px" }}
              >
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12 new-update-box">
                      <div
                        className="updates-block "
                        style={{ border: "#d3d3d3", marginTop: "40px" }}
                      >
                        {" "}
                        <div style={{ background: "white" }}>
                          <h3 style={{ marginLeft: "0" }}>New Update</h3>
                        </div>
                        <div style={{ height: "240px" }}>
                          <div style={{ height: 200 }}>
                            <div
                              suppressContentEditableWarning
                              ref={quillRef}
                            />
                          </div>
                        </div>
                        <div className="save-btn">
                          <button
                            onClick={() => onNewUpdate()}
                            type="button"
                            className="btn btn-primary"
                          >
                            Post
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row updates-main-block">
                <div className="col-md-12">
                  <h3>Updates</h3>
                  {isLoadingupdate ? (
                    <Spinner />
                  ) : (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="updates-block shadow-sm">
                          <div className="updates-section">
                            <div className="col-12">
                              <div className="row">
                                <div className="col-2">
                                  <h4>Date</h4>
                                </div>

                                <div className="col-2">
                                  <h4>Task</h4>
                                </div>
                                <div className="col-2">
                                  <h4>User</h4>
                                </div>
                                <div className="col-6">
                                  <h4>Message</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="updates-paragraph-section">
                            <div className="row">
                              <div className="col-12">
                                {updatesData.updates?.map((msg, index) => {
                                  return (
                                    <div
                                      className="row"
                                      style={{ padding: "0.5%" }}
                                      key={index}
                                    >
                                      <div
                                        style={{
                                          marginTop: "2%",
                                        }}
                                        className="col-2"
                                      >
                                        {moment
                                          .utc(msg?.created_at)
                                          .format("MM/DD/YYYY")}{" "}
                                      </div>

                                      <div
                                        style={{
                                          marginTop: "2%",
                                        }}
                                        className="col-2"
                                      >
                                        {msg?.task}
                                      </div>
                                      <div
                                        style={{
                                          marginTop: "2%",
                                        }}
                                        className="col-2"
                                      >
                                        {msg?.user}
                                      </div>
                                      <div
                                        className="col-6"
                                        style={{
                                          textAlign: "justify",
                                          marginTop: "1%",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        {Parser(msg?.message)}{" "}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              marginTop: "30px",
                              marginRight: "50px",
                            }}
                          >
                            {" "}
                            <footer>
                              <Pagination
                                activePage={page}
                                itemsCountPerPage={limit}
                                totalItemsCount={updatesData?.count}
                                pageRangeDisplayed={5}
                                onChange={(page) => handlelinksUpload(page)}
                                itemClass="page-item"
                                linkClass="page-link"
                              />
                            </footer>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container copy-writes">
          <footer className="row d-flex flex-wrap justify-content-between align-items-center my-4">
            <div className="col-md-4">
              <a
                href="/"
                className="footer-icon mb-3 me-2 mb-md-0 text-muted text-decoration-none"
              >
                <img src="assests/img/footer-icon.svg" alt="" height="15" />
              </a>
              <span className="text-muted">BusinessNET MarketingEngine</span>
              <br />
              <span className="text-muted1">Version 1.0</span>
            </div>

            <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
              <li className="ms-3">
                Privacy policy. Terms of Use. Copyright © 2022 BusinessNET.
              </li>
            </ul>
          </footer>
        </div>
      </div>
    </>
  );
};
