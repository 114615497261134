import { styled } from "@mui/material/styles";

const StyledComponentLibrary = (component) => {
  return {
    StyledTableRow: styled(component)(({ theme }) => ({
      cursor: "pointer",
    })),
    StyledHeaderTableRow: styled(component)(({ theme }) => ({
      backgroundColor: "#f1f1f1",
      height: "30px",
      paddingLeft: "5px",
    })),
    StyledHeaderCell: styled(component)(({ theme }) => ({
      fontFamily: "SF-Compact-Display-Medium",
    })),
    StyledSelect: styled(component)(({ theme }) => ({
      width: "-webkit-fill-available",
    })),
    StyledBox: styled(component)(({ theme }) => ({
      margin: 1,
    })),
  };
};

export default StyledComponentLibrary;
