import React, { useState, useEffect } from "react";
import "./task.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Header } from "../../Header";
import axios from "axios";
import Modal from "react-modal";
import Swal from "sweetalert2";
import Spinner from "../../AdminSpinner";
import { Multiselect } from "multiselect-react-dropdown";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ReactQuill, { Quill } from "react-quill";
import MagicUrl from "quill-magic-url";
import { SideNav } from "../../SideNav";
Quill.register("modules/magicUrl", MagicUrl);
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    height: "215px",
    width: "330px",
    padding: "0px",
    overflow: "hidden",
  },
};
export const Task = () => {
  const { task_id } = useParams();
  let navigate = useNavigate();
  const [skills, setSkills] = useState([]);
  const [skillData, setSkillData] = useState([]);
  const [editSkill, setEditSkill] = useState([]);
  const [checkListData, setCheckListData] = useState([]);
  const [refresh1, setRefresh1] = useState(false);
  const [notes, setNotes] = useState("");
  const [checklist, setCheckList] = useState([]);
  const [deleteChecklist, setDeleteCheckList] = useState([]);
  const [budgetted_hours, setBudgetted_hours] = useState(0);
  const [list_item, setListItem] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [task_name, setTask_Name] = useState("");

  const openModal = () => {
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
  }

  const handleDeleteCheckList = async () => {
    let delete_checklist = deleteChecklist.map((list) => list.id);
    if (delete_checklist.length === 0) {
      Swal.fire("", "Select an item in checklist first");
      return;
    }
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/delete_multiple_checklists`,
      { delete_checklist }
    );
    setRefresh1(!refresh1);
  };

  const handleAddChecklistitem = async () => {
    setIsOpen(false);
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/add_checklist`,
      {
        list_item,
        task_id,
      } //add service properties
    );
    setListItem("");
    setRefresh1(!refresh1);
  };

  const handleCheckboxlist = (e, checklist_id) => {
    const { checked } = e.target;

    // Case 1 : The user checks the box
    if (checked) {
      let checkidadd = deleteChecklist.includes(
        (list) => list.id !== checklist_id
      );
      if (!checkidadd) {
        setDeleteCheckList([
          ...deleteChecklist,
          { id: checklist_id, status: true },
        ]);
      }
      setCheckList([...checklist, { id: checklist_id, status: true }]);
    }
 
    // Case 2  : The user unchecks the box
    else {
      let updated = deleteChecklist.filter((list) => list.id !== checklist_id);
      setDeleteCheckList(updated);
      setCheckList([...checklist, { id: checklist_id, status: false }]);
    }
  };
  const handleSelectedSkill = (data, item) => {
    setSkills([...skills, item.id]);
  };
  const handleDeSelectedSkill = (data, item) => {
    let updatedSkills = skills.filter((e) => e !== item.id);
    setSkills(updatedSkills);
  };
  const handleEditTask = async () => {
    setLoading(true);
    let checklist_arr = [...checklist];

    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/edit_task`,
      {
        task_id,
        skills,
        notes,
        budgetted_hours,
        checklist,
        task_name,
      } //add service properties
    );
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/change_checklists_status`,
      {
        checklist_arr,
      } //add service properties
    );
    navigate("/task-list");
  };
  const getChecklistData = async () => {
    const singleChecklistTask = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/get_task_checklists`,
      {
        task_id,
      }
    );
    setCheckListData(singleChecklistTask?.data?.data);
    setDeleteCheckList(
      singleChecklistTask?.data?.data?.filter((list) => list.status === true)
    );
  };
  const getData = async () => {
    setNotes("");
    setBudgetted_hours("");
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/get_skills_all`
    );

    const singleTask = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/get_single_task`,
      {
        task_id,
      }
    );

    setEditSkill(singleTask?.data.data[0].skills);
    setTask_Name(singleTask?.data.data[0].task_name);
    setNotes(singleTask?.data.data[0].notes);
    setBudgetted_hours(singleTask?.data.data[0].budgetted_hours);
    setSkillData(response.data);
    setSkills(singleTask?.data.data[0].skills.map((idName) => idName.id));
    setLoading(false);
  };

  useEffect(() => {
    if (
      localStorage.role === "account_manager" ||
      localStorage.role === "user"
    ) {
      navigate("/");
    }
    setNotes("");
    setBudgetted_hours("");
    getData();
  }, []);
  useEffect(() => {
    if (
      localStorage.role === "account_manager" ||
      localStorage.role === "user"
    ) {
      navigate("/");
    }

    getChecklistData();
  }, [refresh1]);
  let namesEdit = editSkill?.map((editvalue) => {
    return { name: editvalue.name, id: editvalue.id };
  });
  let options = skillData.data?.map((skill) => {
    return {
      id: skill.id,
      name: skill.name,
    };
  });
  const handleCreateChecklistKeyPress = async (event) => {
    if (event.key === "Enter") {
      handleAddChecklistitem(event);
    }
  };
  const handleNotesEditorChange = (html) => {
    setNotes(html);
  };

  const modules = {
    magicUrl: true,
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ header: [1, 2, false] }],
      ["clean"],
      ["link"],
    ],
  };
  return (
    <>
      <Header />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        <div
          style={{
            display: "flex",
            background: "grey",
            justifyContent: "space-between",
            padding: "0px",
          }}
        >
          <div
            style={{ marginLeft: "10px", marginTop: "4px", fontWeight: "bold" }}
          >
            {"Add Checklist Item"}
          </div>

          <div
            onClick={() => setIsOpen(false)}
            style={{
              marginRight: "5px",

              cursor: "pointer",
            }}
          >
            <img
              src="/assests/img/close-modal.svg"
              width="20"
              alt="modal close"
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "15px", marginTop: "30px" }}>
          <div className="col-md-3 label">Name :</div>
          <div className="col-md-5">
            <input
              className="all-input"
              type="text"
              name="list_item"
              value={list_item}
              onChange={(e) => setListItem(e.target.value)}
              onKeyPress={handleCreateChecklistKeyPress}
              required
            />
          </div>
        </div>

        <button
          type="btn"
          className="all-modal-button btn btn-primary"
          style={{
            float: "right",
            marginRight: "35px",
            marginTop: "70px",
          }}
          onClick={() => handleAddChecklistitem()}
        >
          Save
        </button>
      </Modal>
      <div className="task">
        <div className="container">
          <div className="admin-main-section d-flex align-items-start">
            <SideNav />
            {isLoading ? (
              <Spinner />
            ) : (
              <div className="tab-content w-100">
                <div className="tab-pane fade show active">
                  <div className="task-Block">
                    <div className="task-header shadow-sm">
                      <div className="task-heading">
                        <div className="row">
                          <h1 className="w-50">
                            Task &nbsp; &rarr; &nbsp; {task_name ?? null}
                          </h1>
                        </div>
                      </div>
                      <div className="container bg-white inside-table">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row mt-4">
                              <div className="col-md-7">
                                <label className="rate">Task Name:</label>
                                <input
                                  // defaultValue={budgetted_hours}
                                  onChange={(e) => setTask_Name(e.target.value)}
                                  type="text"
                                  value={task_name}
                                  className="shadow-sm  form-select-sm"
                                  aria-label=".form-select-sm example"
                                ></input>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-6">
                                <div
                                  className="mt-4 mb-4"
                                  style={{ marginBottom: "5%" }}
                                >
                                  <div className="row note_block">
                                    <div className="form-group editor">
                                      <label htmlFor="note">Notes</label>
                                      <br />
                                      <ReactQuill
                                        style={{
                                          height: "250px",
                                          background: "white",
                                        }}
                                        modules={modules}
                                        value={notes}
                                        onChange={handleNotesEditorChange}
                                        name="note"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-4 mb-4">
                                  <div
                                    className="col-md-7"
                                    style={{ marginTop: "50px" }}
                                  >
                                    <label className="rate">Budget hours</label>
                                    <input
                                      onChange={(e) =>
                                        setBudgetted_hours(e.target.value)
                                      }
                                      type="number"
                                      value={budgetted_hours}
                                      className="shadow-sm currency form-select-sm"
                                      aria-label=".form-select-sm example"
                                    ></input>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mt-4 mb-4">
                                  <div className="skill label">
                                    Edit checklist
                                  </div>
                                  <div className="checklistblock shadow-sm">
                                    <div className="row">
                                      {checkListData?.map(
                                        (checkvalue, index) => {
                                          return (
                                            // <div className="row">
                                            <ul
                                              // className="form-check"
                                              key={index}
                                            >
                                              <li
                                                style={{
                                                  listStyle: "none",
                                                  // flex: "1 0 25%"
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    paddingRight: "4px",
                                                  }}
                                                  className="form-check-label"
                                                  htmlFor="account-manager"
                                                >
                                                  <input
                                                    style={{
                                                      display: "flex",
                                                      // marginRight: "3px",
                                                    }}
                                                    className="form-check-input shadow-sm checkbox"
                                                    type="checkbox"
                                                    name="checklist"
                                                    // checked={
                                                    //   namesEdit.includes(skill.name)
                                                    //     ? true
                                                    //     : false
                                                    // }
                                                    defaultChecked={
                                                      checkvalue?.status
                                                    }
                                                    // value={skill.name}
                                                    onChange={(e) =>
                                                      handleCheckboxlist(
                                                        e,
                                                        checkvalue.id
                                                      )
                                                    }
                                                  />

                                                  {checkvalue.list_item}
                                                </label>
                                              </li>
                                            </ul>
                                            // </div>
                                          );
                                        }
                                      )}
                                    </div>
                                    <div className="add-del-btn mt-3 mb-3">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => openModal()}
                                      >
                                        Add New
                                      </button>
                                      <button
                                        onClick={() => handleDeleteCheckList()}
                                        type="button"
                                        className="btn btn-danger"
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="col-md-5 label">
                                  Select Skills
                                </div>
                                <Multiselect
                                  options={options}
                                  selectedValues={namesEdit}
                                  onSelect={handleSelectedSkill} // Function will trigger on select event
                                  onRemove={handleDeSelectedSkill} // Function will trigger on remove event
                                  displayValue="name"
                                />
                              </div>
                            </div>
                            <div
                              className="row"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "20px",
                              }}
                            >
                              <div
                                className="col-md-4"
                                style={{ marginBottom: "15px" }}
                              >
                                <button
                                  type="button"
                                  style={{ width: "100%" }}
                                  className="btn btn-primary"
                                  onClick={() => handleEditTask()}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
          <symbol id="bootstrap" viewBox="0 0 118 94">
            <title>Bootstrap</title>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.509 0c-6.733 0-11.715 5.893-11.492 12.284.214 6.14-.064 14.092-2.066 20.577C8.943 39.365 5.547 43.485 0 44.014v5.972c5.547.529 8.943 4.649 10.951 11.153 2.002 6.485 2.28 14.437 2.066 20.577C12.794 88.106 17.776 94 24.51 94H93.5c6.733 0 11.714-5.893 11.491-12.284-.214-6.14.064-14.092 2.066-20.577 2.009-6.504 5.396-10.624 10.943-11.153v-5.972c-5.547-.529-8.934-4.649-10.943-11.153-2.002-6.484-2.28-14.437-2.066-20.577C105.214 5.894 100.233 0 93.5 0H24.508zM80 57.863C80 66.663 73.436 72 62.543 72H44a2 2 0 01-2-2V24a2 2 0 012-2h18.437c9.083 0 15.044 4.92 15.044 12.474 0 5.302-4.01 10.049-9.119 10.88v.277C75.317 46.394 80 51.21 80 57.863zM60.521 28.34H49.948v14.934h8.905c6.884 0 10.68-2.772 10.68-7.727 0-4.643-3.264-7.207-9.012-7.207zM49.948 49.2v16.458H60.91c7.167 0 10.964-2.876 10.964-8.281 0-5.406-3.903-8.178-11.425-8.178H49.948z"
            ></path>
          </symbol>
          <symbol id="home" viewBox="0 0 16 16">
            <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" />
          </symbol>
          <symbol id="speedometer2" viewBox="0 0 16 16">
            <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z" />
            <path
              fillRule="evenodd"
              d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"
            />
          </symbol>
          <symbol id="table" viewBox="0 0 16 16">
            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
          </symbol>
          <symbol id="people-circle" viewBox="0 0 16 16">
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
            <path
              fillRule="evenodd"
              d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
            />
          </symbol>
          <symbol id="grid" viewBox="0 0 16 16">
            <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
          </symbol>
          <symbol id="collection" viewBox="0 0 16 16">
            <path d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zm1.5.5A.5.5 0 0 1 1 13V6a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13z" />
          </symbol>
          <symbol id="calendar3" viewBox="0 0 16 16">
            <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
            <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
          </symbol>
          <symbol id="chat-quote-fill" viewBox="0 0 16 16">
            <path d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM7.194 6.766a1.688 1.688 0 0 0-.227-.272 1.467 1.467 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 5.734 6C4.776 6 4 6.746 4 7.667c0 .92.776 1.666 1.734 1.666.343 0 .662-.095.931-.26-.137.389-.39.804-.81 1.22a.405.405 0 0 0 .011.59c.173.16.447.155.614-.01 1.334-1.329 1.37-2.758.941-3.706a2.461 2.461 0 0 0-.227-.4zM11 9.073c-.136.389-.39.804-.81 1.22a.405.405 0 0 0 .012.59c.172.16.446.155.613-.01 1.334-1.329 1.37-2.758.942-3.706a2.466 2.466 0 0 0-.228-.4 1.686 1.686 0 0 0-.227-.273 1.466 1.466 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 10.07 6c-.957 0-1.734.746-1.734 1.667 0 .92.777 1.666 1.734 1.666.343 0 .662-.095.931-.26z" />
          </symbol>
          <symbol id="cpu-fill" viewBox="0 0 16 16">
            <path d="M6.5 6a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
            <path d="M5.5.5a.5.5 0 0 0-1 0V2A2.5 2.5 0 0 0 2 4.5H.5a.5.5 0 0 0 0 1H2v1H.5a.5.5 0 0 0 0 1H2v1H.5a.5.5 0 0 0 0 1H2v1H.5a.5.5 0 0 0 0 1H2A2.5 2.5 0 0 0 4.5 14v1.5a.5.5 0 0 0 1 0V14h1v1.5a.5.5 0 0 0 1 0V14h1v1.5a.5.5 0 0 0 1 0V14h1v1.5a.5.5 0 0 0 1 0V14a2.5 2.5 0 0 0 2.5-2.5h1.5a.5.5 0 0 0 0-1H14v-1h1.5a.5.5 0 0 0 0-1H14v-1h1.5a.5.5 0 0 0 0-1H14v-1h1.5a.5.5 0 0 0 0-1H14A2.5 2.5 0 0 0 11.5 2V.5a.5.5 0 0 0-1 0V2h-1V.5a.5.5 0 0 0-1 0V2h-1V.5a.5.5 0 0 0-1 0V2h-1V.5zm1 4.5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3A1.5 1.5 0 0 1 6.5 5z" />
          </symbol>
          <symbol id="gear-fill" viewBox="0 0 16 16">
            <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
          </symbol>
          <symbol id="speedometer" viewBox="0 0 16 16">
            <path d="M8 2a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-1 0V2.5A.5.5 0 0 1 8 2zM3.732 3.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 8a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 7.31A.91.91 0 1 0 8.85 8.569l3.434-4.297a.389.389 0 0 0-.029-.518z" />
            <path
              fillRule="evenodd"
              d="M6.664 15.889A8 8 0 1 1 9.336.11a8 8 0 0 1-2.672 15.78zm-4.665-4.283A11.945 11.945 0 0 1 8 10c2.186 0 4.236.585 6.001 1.606a7 7 0 1 0-12.002 0z"
            />
          </symbol>
          <symbol id="toggles2" viewBox="0 0 16 16">
            <path d="M9.465 10H12a2 2 0 1 1 0 4H9.465c.34-.588.535-1.271.535-2 0-.729-.195-1.412-.535-2z" />
            <path d="M6 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0 1a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm.535-10a3.975 3.975 0 0 1-.409-1H4a1 1 0 0 1 0-2h2.126c.091-.355.23-.69.41-1H4a2 2 0 1 0 0 4h2.535z" />
            <path d="M14 4a4 4 0 1 1-8 0 4 4 0 0 1 8 0z" />
          </symbol>
          <symbol id="tools" viewBox="0 0 16 16">
            <path d="M1 0L0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.356 3.356a1 1 0 0 0 1.414 0l1.586-1.586a1 1 0 0 0 0-1.414l-3.356-3.356a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0zm9.646 10.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708zM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11z" />
          </symbol>
          <symbol id="chevron-right" viewBox="0 0 16 16">
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </symbol>
          <symbol id="geo-fill" viewBox="0 0 16 16">
            <path
              fillRule="evenodd"
              d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"
            />
          </symbol>
        </svg>

        <div className="container copy-writes">
          <footer className="row d-flex flex-wrap justify-content-between align-items-center my-4">
            <div className="col-md-4">
              <a
                href="/"
                className="footer-icon mb-3 me-2 mb-md-0 text-muted text-decoration-none"
              >
                <img src="/assests/img/footer-icon.svg" alt="" height="15" />{" "}
              </a>
              <span className="text-muted">BusinessNET MarketingEngine</span>
              <br />
              <span className="text-muted1">Version 1.0</span>
            </div>

            <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
              <li className="ms-3">
                Privacy policy. Terms of Use. Copyright © 2022 BusinessNET.
              </li>
            </ul>
          </footer>
        </div>
      </div>
    </>
  );
};
