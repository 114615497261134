import React, { useState, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Tasks from './tasks';

const columns = [
    { name: "Service", selector: row => row.task_name },
    { name: "Status", selector: row => row.status },
    { name: "Spent hours", selector: row => row.spent_hours },
    { name: "Start date", selector: row => row.start_date },
    { name: "Due date", selector: row => row.due_date },
    { name: "Assigned to", selector: row => row.task_name },
];

const DynamicCollapsibleTable = (props) => {
    const { data = [], users = [] } = props;
    const [openStates, setOpenStates] = useState(() => data.map((x) => false));

    const handleClick = (index) => {
        const newStates = [...openStates];
        newStates[index] = !newStates[index];
        setOpenStates(newStates);
    };
    const renderServiceRows = () => {
        let tasks = [], memoMapData;
        return data.map((row, index) => {
            tasks = row?.tasks || [];
            // memoMapData = useMemo(() => mapUsersData(users, tasks, index));
            return (
                <>
                    <TableRow style={{}} key={index} className="table-row">
                        <TableCell className="table-cell">
                            <IconButton style={{fontSize:"14px",fontWeight:"bold"}}
                                aria-label="expand row"
                                size="small"
                                onClick={() => handleClick(index)}
                            >
                                {openStates[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                {row.service_name}
                            </IconButton>
                        </TableCell>
                    </TableRow>
                    <Tasks
                        tasks={tasks}
                        index={index}
                        isOpen={openStates[index]}
                        users={users}
                        // users={memoMapData()}
                    />
                </>
            )
        })
    };

    return (
        <div className="row projects-block">
            <div className="col-md-12" style={{padding:"0",borderRadius:"0"}}>
                <div className="project_pages-list-block shadow-sm">
                    <Paper elevation={3} variant="outlined">
                        <TableContainer>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {renderServiceRows()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            </div>
        </div>
    );
};

export default DynamicCollapsibleTable;