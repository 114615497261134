import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import StyledComponentLibrary from './styles';
import CustomMenuItem from './CustomMenuItem';
import { updateUserTask } from './actions'
import { removeDuplicates } from './utils';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const StyledTableRow = StyledComponentLibrary(TableRow).StyledTableRow;
const StyledSelect = StyledComponentLibrary(Select).StyledSelect;

const CustomRow = (props) => {
    const { task = [], index, users = [] } = props;
    const navigationTo = useNavigate();
    const [userNames, setUserNames] = useState([]);
    const [isSelected, setSelected] = useState(false);
    useEffect(() => {
        let defaultValue = getDefaultUserNames();
        setUserNames(defaultValue);
    }, [])

    const getDefaultUserNames = () => {
        let assignedUsers = [], assignedUsersId = [];
        if (Object.keys(task).length > 0 && users.length > 0) {
            assignedUsersId = task?.assigned_users || [];
            assignedUsersId = removeDuplicates(assignedUsersId); //Remove duplicates if any
            for (let i = 0; i < assignedUsersId.length; i++) {
                let val = users.find((v) => {
                    if (v.userId === assignedUsersId[i]) return true;
                })
                if (val) assignedUsers.push(val);
            }
        }
        return assignedUsers;

    };

    const onChange = (event, child) => {
        const ALLOCATE = 'allocate_task';
        const DEALLOCATE = 'deallocate_task';
        let { target: { value } } = event;
        const taskId = task?.id;
        const userId = child?.props?.value?.userId;
        let action = (value.length > userNames.length) ? ALLOCATE : DEALLOCATE;
        setUserNames(value);
        updateUserTask(action, userId, taskId);
    };
    const onRowClick = () => {
        setSelected(!isSelected);
        navigationTo(`/task-page/${task?.id}`);
    };
    const onClick = (e, type) => {
        switch (type) {
            case "row":
                onRowClick()
        }
    };

    const renderDropdownBody = () => {
        return users.map((user, index) => {
            return (
                <MenuItem
                    key={user?.userId}
                    value={user}
                >
                    <Checkbox checked={userNames.map(u => u.name).indexOf(user?.name) > -1} />
                    <ListItemText primary={user?.name} />
                </MenuItem>
            )
        })
    };

    return (
        <StyledTableRow
            key={index}
            onClick={(e) => onClick(e, 'row')}
            selected={isSelected}
        >
            <TableCell>
                {task.task_name}
            </TableCell>
            <TableCell>{task.status}</TableCell>
            <TableCell>{task.spent_hours}</TableCell>
            <TableCell>{task.start_date}</TableCell>
            <TableCell>{task.due_date}</TableCell>
            <TableCell sx={{ m: 1, width: 300 }}>
                <StyledSelect
                    key={index}
                    multiple
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    onChange={(e, c) => onChange(e, c)}
                    value={userNames}
                    input={<OutlinedInput />}
                    autoWidth={true}
                    native={false}
                    size={"small"}
                    renderValue={(users) => users.map(user => user?.name).toString()}
                    MenuProps={MenuProps}
                >
                    {renderDropdownBody()}
                </StyledSelect>
            </TableCell>
        </StyledTableRow >
    )

};

export default CustomRow;

