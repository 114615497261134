import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Outlet, useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Badge } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";
import { Avatar } from "@mui/material";
import "./notifications.css";
import moment from "moment";
import setAuthToken from "../utils/setAuthToken";
import "./header.css";
import { useQuery, useQueryClient } from "react-query";
import { Spinner } from "react-bootstrap";
import Parser from "html-react-parser";

export const Header = () => {
  const queryClient = useQueryClient();
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [visible, setVisible] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    setAuthToken(false);
    queryClient.removeQueries();
    navigate("/");
  };

  const { status, data, refetch } = useQuery(
    "notify",
    async () => {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/get_notifications`,
        {
          limit: "3",
          page: "1",
        }
      );
      return res?.data;
    },
    {
      refetchInterval: 300000,
    }
  );
  if (status === "loading") return <Spinner />;
  if (status === "error") {
    localStorage.clear();

    queryClient.removeQueries();
    navigate("/");
  }

  const handleChangeStatus = async (id) => {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/update_notification_status`,
      { is_red: "1", notification_id: id }
    );
    refetch();
  };
  const handleChangeStatusActive = async (id) => {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/update_notification_active`,
      { is_active: "0", notification_id: id }
    );
  };

  data?.data?.filter((datafilter) => datafilter.is_red === 0);
  const handleNotificationClick = (id) =>{
    setVisible(!visible);
    navigate(`/project-page/${id}`)
  }
  return (
    <>
      <div className="header">
        <div className="container">
          <nav className="navbar navbar-expand-lg bg-white shadow-sm">
            <div className="container-fluid">
              <a className="navbar-brand" href="/">
                <img
                  src="/assests/img/logo.svg"
                  alt="Header Logo"
                  style={{
                    width: "auto",
                    height: "25px",
                    display: "inline-block",
                  }}
                  className="align-top"
                />
                <span>MarketingEngine</span>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarText"
                aria-controls="navbarText"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarText">
                <ul className="navbar-nav mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link to={"/"} style={{ textDecoration: "none" }}>
                      {localStorage.role === "admin" ? "Admin" : ""}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"/client-list"}
                      style={{ textDecoration: "none" }}
                    >
                      {localStorage.role !== "user" ? "Clients" : ""}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"/project-list"}
                      style={{ textDecoration: "none" }}
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"/time-report"}
                      style={{ textDecoration: "none" }}
                    >
                       {localStorage.role == "user" ? "Time Report" : ""}
                      
                    </Link>
                  </li>
                </ul>
                <ul className="navbar-nav icon">
                  <li>
                    {" "}
                    <Dropdown
                    onClick={() => setVisible(!visible)}
                    show={visible}
                    >
                      <Dropdown.Toggle
                        variant="light"
                        
                        className="bg-white border-0 p-0 "
                        id="dropdown-basic"
                      >
                        <div>
                          <Badge badgeContent={data?.unread} color="primary">
                            <img
                              src="/assests/img/bell.svg"
                              alt="Logo"
                              style={{ width: "40px" }}
                              className="rounded-pill"
                            />
                          </Badge>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ minWidth: "400px" }}>
                        <div>
                          {data.data?.map((notification) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  padding: "7px",
                                  cursor: "pointer",
                                }}
                               
                                className="notification-div"
                                key={notification.id}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                  onClick={() =>
                                       handleChangeStatus(notification.id)
                                 }
                                >
                                  {" "}
                                  <div
                                  className="mydivouter"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                    
                                  >
                                   
                                    <Avatar
                                      sx={{ width: 50, height: 50 }}
                                      style={{ marginRight: "10px" }}
                                    />
                                     
                                    <span style={{paddingRight:"30px"}}
                                    
                                    onClick={()=>handleNotificationClick(notification?.project_id)}
                                    >{Parser(notification?.message)}</span>
                                    <button type="button" aria-label="Close" className="mybuttonoverlap close" onClick={() =>
                                       handleChangeStatusActive(notification.id)
                                 }
                                 style={{background:"transparent",border:"none"}}>
                                    <span aria-hidden="true" style={{fontSize:"30px"}}>&times;</span>
                                  </button>
                                  </div>
                                  
                                  <div
                                    style={{
                                      marginLeft: "15.6%",
                                      color: "#24a0ed",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {moment(notification?.created_at).fromNow()}
                                  </div>
                                  {notification.is_red === 0 ? (
                                    <div>
                                      {" "}
                                      <FiberManualRecordIcon
                                     style={{ color: "blue",marginLeft:"90%" }}
                                      />
                                      {" "}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  

                                </div>
                              </div>
                            );
                          })}
                        </div>

                        <Dropdown.Item
                          style={{ marginTop: "5px" }}
                          onClick={() => navigate("/notifications")}
                          active
                          className="text-center"
                        >
                          Show All
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  <li>
                    <Dropdown
                      className="toggle-dropdown"
                      show={show}
                      onMouseEnter={showDropdown}
                      onMouseLeave={hideDropdown}
                    >
                      <Dropdown.Toggle
                        variant="light"
                        className="bg-white border-0 p-0 "
                        id="dropdown-basic"
                      >
                        <li className="nav-item" style={{ minWidth: "50px" }}>
                          {/* <a> */}
                          <img
                            src="/assests/img/user.svg"
                            alt="Logo"
                            style={{ width: "40px" }}
                            className="rounded-pill"
                          />
                          {/* </a> */}
                        </li>
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ maxWidth: "100px" }}>
                        <Dropdown.Item onClick={() => handleLogout()} active>
                          LogOut
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <Outlet />
    </>
  );
};
