import axios from "axios";

const setAuthToken = (token) => {
  if (token) {
    // Apply to every request

    axios.defaults.headers.common["Authorization"] = `bearer ${token}`;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
    localStorage.clear();
    // localStorage.removeItem("jwtToken", token);
  }
};

export default setAuthToken;
