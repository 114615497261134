import React from "react";
import "./project.css";
import { useNavigate } from "react-router";
export const Project = () => {
  let navigate = useNavigate();
  return (
    <>
      <div className="project">
        <div className="container project-Block">
          <div className="project-header shadow-sm">
            <div className="project-heading">
              <h1>
                Project <small></small> Amazon
              </h1>
            </div>
            <div className="container bg-white">
              <div className="row project-detail-section">
                <div className="col-md-8">
                  <h4>Client Info</h4>
                  <div className="client-info-block shadow-sm">
                    <div className="row">
                      <div className="col6 title-color">
                        <p>Business Name:</p>
                        <p>Owner Name:</p>
                        <p>Phone Number:</p>
                        <p>Email:</p>
                      </div>
                      <div className="col6">
                        <p>Amazon.com, Inc.</p>
                        <p>Jeff Bezos</p>
                        <p>555 4202</p>
                        <p>jeff@amazon.com</p>
                      </div>
                      <div className="col6 title-color">
                        <p>Business Name:</p>
                        <p>Owner Name:</p>
                      </div>
                      <div className="col6">
                        <p>---</p>
                        <p>---</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="modify-block">
                    <h3>Save</h3>
                  </div>
                  <div className="notes-block">
                    <div className="notes-paragraph-section">
                      <p>
                        Account Manager: <span className="cc">CC</span>
                      </p>
                      <p>
                        Date: <span>25/8/22</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row invoice-project-block">
                <div className="col-md-11">
                  <div className="projects-list-block">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="table-project-heading">
                            Project Details
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        className="table-group-divider"
                        // onClick={() => navigate("/project-page")}
                      >
                        <tr>
                          <td className="projecct-name">
                            Project: <span>ecommerce site</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row projects-block">
                <div className="col-md-11">
                  <div className="projects-list-block">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="table-project-heading">
                            Agency Service
                          </th>
                          <th className="table-project-heading">Details</th>
                          <th className="table-project-heading">Service</th>
                          <th className="table-project-heading">Assign</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody
                        className="table-group-divider"
                        onClick={() => navigate("/manage-service")}
                        style={{ cursor: "pointer" }}
                      >
                        <tr>
                          <td>Marketing Funnel</td>
                          <td>Property Campaign</td>
                          <td>one-off</td>
                          <td>
                            <span className="assign-to">JH</span>
                            <span className="assign-to">CC</span>
                            <span className="assign-to">BR</span>
                            <span className="assign-to-dropdown drop1">
                              <div className="dropdown">
                                <button
                                  className="btn btn-default"
                                  type="button"
                                  id="dropdownMenu1"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="fa-solid fa-ellipsis"></i>
                                </button>
                                <ul
                                  className="dropdown-menu checkbox-menu allow-focus shadow-sm"
                                  aria-labelledby="dropdownMenu1"
                                >
                                  <li>
                                    <label>
                                      {" "}
                                      <input type="checkbox" /> Ben{" "}
                                    </label>
                                  </li>
                                  <li>
                                    <label>
                                      {" "}
                                      <input type="checkbox" /> Jeremy{" "}
                                    </label>
                                  </li>
                                  <li>
                                    <label>
                                      {" "}
                                      <input type="checkbox" /> Isabella{" "}
                                    </label>
                                  </li>
                                  <li>
                                    <label>
                                      {" "}
                                      <input type="checkbox" /> Briton{" "}
                                    </label>
                                  </li>
                                  <li>
                                    <label>
                                      {" "}
                                      <input type="checkbox" /> Christian{" "}
                                    </label>
                                  </li>
                                  <li>
                                    <label>
                                      {" "}
                                      <input type="checkbox" /> Amar{" "}
                                    </label>
                                  </li>
                                </ul>
                              </div>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Facebook Ads</td>
                          <td>Ad Management</td>
                          <td>Monthly</td>
                          <td>
                            <span className="assign-to">JH</span>
                            <span className="assign-to">CC</span>
                            <span className="assign-to">BR</span>
                            <span className="assign-to-dropdown drop2">
                              <div className="dropdown">
                                <button
                                  className="btn btn-default"
                                  type="button"
                                  id="dropdownMenu1"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="fa-solid fa-ellipsis"></i>
                                </button>
                                <ul
                                  className="dropdown-menu checkbox-menu allow-focus shadow-sm"
                                  aria-labelledby="dropdownMenu1"
                                >
                                  <li>
                                    <label>
                                      {" "}
                                      <input type="checkbox" /> Ben{" "}
                                    </label>
                                  </li>
                                  <li>
                                    <label>
                                      {" "}
                                      <input type="checkbox" /> Jeremy{" "}
                                    </label>
                                  </li>
                                  <li>
                                    <label>
                                      {" "}
                                      <input type="checkbox" /> Isabella{" "}
                                    </label>
                                  </li>
                                  <li>
                                    <label>
                                      {" "}
                                      <input type="checkbox" /> Briton{" "}
                                    </label>
                                  </li>
                                  <li>
                                    <label>
                                      {" "}
                                      <input type="checkbox" /> Christian{" "}
                                    </label>
                                  </li>
                                  <li>
                                    <label>
                                      {" "}
                                      <input type="checkbox" /> Amar{" "}
                                    </label>
                                  </li>
                                </ul>
                              </div>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="row invoice-cal">
                      <div className="col-5">
                        <p className="add">+Add</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="onboard-block">
                        <h1>Onboard Information</h1>
                        <textarea name="info" rows="3" disabled></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-10">
                  <div className="row link-upload-block">
                    <div className="col-md-6">
                      <div className="row updates-main-block">
                        <div className="col-md-12">
                          <h3>Updates</h3>
                          <div className="updates-block link-updates shadow-sm">
                            <div className="updates-section">
                              <div className="row">
                                <div className="col-2">
                                  <h4>Date</h4>
                                </div>
                                <div className="col-2">
                                  <h4>Client</h4>
                                </div>
                                <div className="col-3">
                                  <h4>Project</h4>
                                </div>
                                <div className="col-3">
                                  <h4>Service</h4>
                                </div>
                                <div className="col-2">
                                  <h4>Task</h4>
                                </div>
                              </div>
                            </div>
                            <div className="updates-paragraph-section">
                              <div className="row"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="invoice-cal">
                        <p className="add-upload">Upload</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row updates-main-block">
                        <div className="col-md-12">
                          <h3>Links</h3>
                          <div className="updates-block link-updates shadow-sm">
                            <div className="updates-section">
                              <div className="row">
                                <div className="col-2">
                                  <h4>Date</h4>
                                </div>
                                <div className="col-2">
                                  <h4>Client</h4>
                                </div>
                                <div className="col-3">
                                  <h4>Project</h4>
                                </div>
                                <div className="col-3">
                                  <h4>Service</h4>
                                </div>
                                <div className="col-2">
                                  <h4>Task</h4>
                                </div>
                              </div>
                            </div>
                            <div className="updates-paragraph-section">
                              <div className="row"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="invoice-cal">
                        <p className="add-upload">Add</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container copy-writes">
          <footer className="row d-flex flex-wrap justify-content-between align-items-center my-4">
            <div className="col-md-4">
              <a
                href="/"
                className="footer-icon mb-3 me-2 mb-md-0 text-muted text-decoration-none"
              >
                <img src="assests/img/footer-icon.svg" alt="" height="15" />
              </a>
              <span className="text-muted">BusinessNET MarketingEngine</span>
              <br />
              <span className="text-muted1">Version 1.0</span>
            </div>

            <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
              <li className="ms-3">
                Privacy policy. Terms of Use. Copyright © 2022 BusinessNET.
              </li>
            </ul>
          </footer>
        </div>
      </div>
    </>
  );
};
