import React, { useState, useEffect } from "react";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import "./login.css";
import Spinner from "./Spinner";
export const Login = () => {
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);

  const handleLoginRequest = async () => {
    setLoading(true);
    let data = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/login_user`,
      { email, password }
    );

    if (data?.data?.status === "false") {
      Swal.fire("", "Invalid Credentials");
      setLoading(false);
      return;
    }
    localStorage.setItem("jwtToken", data?.data?.token);

    setAuthToken(data?.data?.token);

    localStorage.setItem("role", data?.data?.user?.role);
    if (data?.data?.user?.role === "admin") {
      setLoading(false);
      navigate("/team");
    } else if (data?.data?.user?.role === "account_manager") {
      setLoading(false);
      navigate("/client-list");
    } else if (data?.data?.user?.role === "user") {
      setLoading(false);
      navigate("/project-list");
    }

    setLoading(false);
  };
  useEffect(() => {
    if (localStorage.jwtToken) {
      if (localStorage.role === "admin") {
        navigate("/team");
      } else if (localStorage.role === "account_manager") {
        navigate("/client-list");
      } else if (localStorage.role === "user") {
        navigate("/project-list");
      }
    }
  }, []);
  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      handleLoginRequest(event);
    }
  };

  return (
    <>
      <div className="container">
        <div style={{ marginLeft: "25%" }}>
          {isLoading === true ? <Spinner width="35%" /> : null}
        </div>
        <div className="login-block shadow-sm">
          <div className="logo">
            <img src="assests/img/logo.svg" alt="Login page Logo" />
            <p>MarketingEngine</p>
          </div>
          <div className="mb-4 row">
            <label htmlFor="email" className="col-sm-2 col-form-label">
              Email:
            </label>
            <div className="col-sm-10">
              <input
                type="email"
                name="email"
                className="form-control rounded-0 shadow-sm"
                id="userName"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-5 row">
            <label htmlFor="inputPassword" className="col-sm-2 col-form-label">
              Password:
            </label>
            <div className="col-sm-10">
              <input
                type="password"
                name="password"
                className="form-control rounded-0 shadow-sm"
                id="inputPassword"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-auto w-100">
              <button
                onClick={handleLoginRequest}
                className="btn btn-primary float_right"
                // onClick={() => navigate("/team")}
                style={{ cursor: "pointer" }}
              >
                Login
              </button>
            </div>
          </div>
        </div>
        <div className="login-block shadow-sm em">
          <p>Copyright © 2022 BusinessNET MarketingEngine</p>
        </div>
      </div>
    </>
  );
};
