import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
export const SideNav = (prop) => {
  const location = useLocation();

  const firstPath = location.pathname.split("/")[1];

  return (
    <div>
      {" "}
      <div
        className="nav flex-column nav-pills me-3"
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        <Link to="/team">
          <button
            className={`nav-link ${
              firstPath === "team" ||
              firstPath === "new-user" ||
              firstPath === "edit-user"
                ? "active"
                : null
            }`}
            style={{ width: 230 }}
            value={"1"}
          >
            <svg className="bi pe-none me-2" width="16" height="16">
              <use xlinkHref="#home" />
            </svg>
            Team
          </button>
        </Link>

        <Link to="/skills">
          <button
            className={`nav-link ${firstPath === "skills" ? "active" : null}`}
            style={{ width: 230 }}
          >
            <svg className="bi pe-none me-2" width="16" height="16">
              <use xlinkHref="#speedometer2" />
            </svg>
            Skills
          </button>
        </Link>

        <Link to={"/add-service"}>
          <button
            className={`nav-link ${
              firstPath === "add-service" || firstPath === "manage-service"
                ? "active"
                : null
            }`}
            style={{ width: 230 }}
          >
            <svg className="bi pe-none me-2" width="16" height="16">
              <use xlinkHref="#table" />
            </svg>
            Agency Services
          </button>
        </Link>

        <Link to="/task-list">
          <button
            className={`nav-link ${
              firstPath === "task-list" || firstPath === "add-task"
                ? "active"
                : null
            }`}
            style={{ width: 230 }}
          >
            <svg className="bi pe-none me-2" width="16" height="16">
              <use xlinkHref="#grid" />
            </svg>
            Tasks
          </button>
        </Link>
        <Link to="/time-tracker">
          <button
            className={`nav-link ${
              firstPath === "time-tracker" ? "active" : null
            }`}
            style={{ width: 230 }}
          >
            <AccessAlarmsIcon
              style={{ marginLeft: "5px", marginRight: "3px" }}
            />
            Time Tracker
          </button>
        </Link>
      </div>
    </div>
  );
};
