import axios from "axios";
import React, { useState, useEffect, useContext, useMemo } from "react";
import "./timer.css";
import { AppContext } from "../../contexts/AppContext";
import Swal from "sweetalert2";
const Timer = ({ project_task_id }) => {
  const { gettime } = useContext(AppContext);
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);
  const [timeInterval, setTimeInterval] = useState(0);
  const [load, setLoad] = useState(true);
  const [session_id, setSession_id] = useState("");
  const [startTime, setStartTime] = useState("");
  const [submittimeInterval, setSubmittimeInterval] = useState("");
  const toggle = () => {
    if (!isActive) {
      setTime(0);
      setIsActive(!isActive);
      setStartTime(Date.now());
      setSubmittimeInterval(Date.now());
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/start_tracker_time`, {
          project_task_id,
        })
        .then((response) => {
          if (response.data.status === true) {
            setSession_id(response.data.session_id);
          } else {
            setTime(0);
            setIsActive(!isActive);
            Swal.fire("Something went wrong to start tracker.");
          }
        });
    }
    if (isActive) {
      setIsActive(!isActive);
      setLoad(true);
      gettime(session_id, timeInterval, isActive, load);
      setTimeInterval(0);
    }
  };

  const getSubmittime = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/submit_tracker_time`, {
        session_id,
        time: timeInterval,
        stopped: 0,
      })
      .then((response) => {
        if (response?.data?.duplicate) {
          Swal.fire(
            "Oooops",
            "You are running a duplicate tracker, may be on another task. Make sure you run a tracker on one task at a time. We may stop your all trackers."
          );
          setTime(0);
          setIsActive(!isActive);
          setSubmittimeInterval("");
        }
      });
  };
  useEffect(() => {
    let interval = null;

    if (isActive) {
      interval = setInterval(() => {
        setTimeInterval(Date.now() - submittimeInterval);
        setTime(Date.now() - startTime);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);
  useMemo(() => {
    if (isActive && timeInterval >= process.env.REACT_APP_MINUTES * 60000) {
      setSubmittimeInterval(Date.now());

      getSubmittime();
    }
  }, [time]);

  const makeTimeReadable = (t) => {
    const timeInSec = Math.floor(t / 1000);
    const hours = Math.floor(timeInSec / 3600);
    const mins = Math.floor((timeInSec - hours * 3600) / 60);
    const secs = timeInSec - hours * 3600 - mins * 60;
    return `${checkTwoDigits(hours)}:${checkTwoDigits(mins)}:${checkTwoDigits(
      secs
    )}`;
  };
  const checkTwoDigits = (t) => {
    if (t < 10) {
      return "0" + t;
    }
    return t;
  };
  return (
    <>
      Time Tracker
      <span
        style={{
          border: "3px solid black",
          padding: "5px",
          borderRadius: "50%",
          marginLeft: "2%",
        }}
      >
        <i
          style={{ height: "13px", width: "13px" }}
          className={`small fa-solid fa-${isActive ? "pause" : "play"}`}
          onClick={toggle}
        ></i>
      </span>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <span className="shadow-sm">
        <span className={isActive ? "blink_me" : null}>
          <i
            style={{ color: isActive ? "red" : null }}
            className="fa-regular fa-circle-dot"
          ></i>
        </span>
        &nbsp;&nbsp;&nbsp;
        {makeTimeReadable(time)} s
      </span>
    </>
  );
};

export default Timer;
