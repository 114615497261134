import Spinner from "react-bootstrap/Spinner";

function Spinners() {
  return (
    <Spinner
      style={{
        marginLeft: "28%",
        marginTop: "10px",
      }}
      animation="border"
      varient="primary"
    />
  );
}

export default Spinners;
