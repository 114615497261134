import React from "react";
import { AppProvider } from "./contexts/AppContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Login } from "./views/Login";
import { Header } from "./views/Header";
import { Notifications } from "./views/Notifications";
import { ClientPage } from "./views/AccountManager/ClientPage";
import { ClientList } from "./views/AccountManager/ClientsList";
import { Project } from "./views/AccountManager/Project";
import { Quote } from "./views/AccountManager/Quote";
import { ProjectPage } from "./views/User-AccountManager/ProjectPage";
import { ProjectList } from "./views/User-AccountManager/ProjectList";
import { TimeReport } from "./views/User-AccountManager/TimeReport";

import { TaskPage } from "./views/User-AccountManager/TaskPage";
import { Skills } from "./views/Admin/Skills";
import { TimeTracker } from "./views/Admin/time-tracker/TimeTracker";
import { ManageService } from "./views/Admin/agency-services/ManageService";
import { AddService } from "./views/Admin/agency-services/AddService";
import { Task } from "./views/Admin/tasks/Task";
import { TaskList } from "./views/Admin/tasks/TaskList";
import { NewUser } from "./views/Admin/team/NewUser";
import { EditUser } from "./views/Admin/team/EditUser";

import jwt_decode from "jwt-decode";
import { Team } from "./views/Admin/team/Team";
import setAuthToken from "./utils/setAuthToken";
import { PrivateRouteAdmin } from "./PrivateRoutes";
import { useQueryClient } from "react-query";

function App() {
  const queryClient = useQueryClient();

  if (localStorage.jwtToken) {
    setAuthToken(localStorage.jwtToken);
    const decoded = jwt_decode(localStorage.jwtToken);
    // Set user and isAuthenticated
    // Check for expired token
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
      // Logout user
      // localStorage.removeItem("jwtToken");
      // localStorage.removeItem("role");
      localStorage.clear();
      queryClient.removeQueries();

      // Clear current Profile
      // Redirect to login
      window.location.href = "/";
    }
    // Decode token and get user info and exp
  }
  return (
    <>
      {" "}
      <AppProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" name="Login" element={<Login />} />

            <Route element={<PrivateRouteAdmin />}>
              <Route path="/skills" name="skills" element={<Skills />} />
              <Route
                path="/time-tracker"
                name="time-tracker"
                element={<TimeTracker />}
              />

              <Route
                path="/add-service"
                name="add-service"
                element={<AddService />}
              />
              <Route
                path="/manage-service/:service_name/:service_id"
                name="manage-service"
                element={<ManageService />}
              />
              <Route path="/add-task/:task_id" name="task" element={<Task />} />
              <Route path="/task-list" name="taskList" element={<TaskList />} />
              <Route path="/new-user" name="NewUser" element={<NewUser />} />
              <Route
                path="/edit-user/:user_id"
                name="EditUser"
                element={<EditUser />}
              />

              <Route path="/team" name="NewUser" element={<Team />} />

              <Route element={<Header />}>
                <Route
                  path="/notifications"
                  name="notifications"
                  element={<Notifications />}
                />
                <Route
                  path="/client-page/:client_id"
                  name="ClientPage"
                  element={<ClientPage />}
                />
                <Route
                  path="/client-list"
                  name="ClientList"
                  element={<ClientList />}
                />

                <Route
                  path="/project-details"
                  name="ProjectDetails"
                  element={<Project />}
                />
                <Route
                  path="/quote/:quote_id"
                  name="Quote"
                  element={<Quote />}
                />
                <Route
                  path="/project-page/:project_id"
                  name="ProjectPage"
                  element={<ProjectPage />}
                />
                <Route
                  path="/project-list"
                  name="ProjectList"
                  element={<ProjectList />}
                />
                <Route
                  path="/time-report"
                  name="ProjectReport"
                  element={<TimeReport />}
                />
                <Route
                  path="/task-page/:task_id"
                  name="TaskPage"
                  element={<TaskPage />}
                />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </AppProvider>
    </>
  );
}

export default App;
