import React, { useState, useEffect } from "react";
import axios from "axios";
import { Avatar } from "@mui/material";
import "./notifications.css";
import { Outlet, useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import moment from "moment";
import Parser from "html-react-parser";
import Pagination from "react-js-pagination";
import Spinner from "./Spinner";
export const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  let navigate = useNavigate();
  const handlePageChange = (currpage) => {
    setPage(currpage);
    setLoading(true);
    setRefresh(!refresh);
  };
  const getData = async () => {
    const notification_data = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/get_notifications`,
      { limit, page }
    );

    setNotifications(notification_data?.data);
  };
  useEffect(() => {
    getData();
    setLoading(false);
  }, [refresh]);
  const handleChangeStatus = async (id) => {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/update_notification_status`,
      { is_red: "1", notification_id: id }
    );
    setRefresh(!refresh);
  };
  const handleChangeStatusActive = async (id) => {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/update_notification_active`,
      { is_active: "0", notification_id: id }
    );

  };
  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <div className="client_list">
        <div className="container client_list-Block">
          <div className="client_list-header shadow-sm">
            <div className="client_list-heading">
              <div className="row">
                <h1 style={{ width: "8%" }}>Notifications</h1>
              </div>
            </div>
            <div className="container bg-white shadow-sm inside-table">
              <div>
                {notifications?.data?.map((notification) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        padding: "7px",
                        cursor: "pointer",
                      }}
                      key={notification.id}
                      className="notification-div"
                     
                    >
                      <div
                      className="Notiouter"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "65%",
                        }}
                        onClick={() =>
                          handleChangeStatus(notification.id)
                    }
                      >
                         <button type="button" aria-label="Close" className="Notioverlap close" onClick={() =>
                                       handleChangeStatusActive(notification.id)
                                 }
                                 style={{background:"transparent",border:"none"}}>
                                    <span aria-hidden="true" style={{fontSize:"30px"}}>&times;</span>
                                  </button>
                        {" "}
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          
                          <Avatar
                            sx={{ width: 50, height: 50 }}
                            style={{ marginRight: "3%" }}
                          />
                          <span
                          onClick={() =>
                            navigate(`/project-page/${notification.project_id}`)
                          }
                          >
                            {Parser(notification?.message)}
                            {"\n"}
                            <div
                              style={{
                                color: "#24a0ed",
                                fontWeight: "400",
                              }}
                            >
                              {moment(notification?.created_at).fromNow()}
                            </div>
                          </span>
                        </div>
                      </div>
                      <div
                      
                        style={{
                          
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                       
                        {notification.is_red === 0 ? (
                          <div>
                            {" "}
                            <FiberManualRecordIcon
                                       style={{ color: "blue" }}
                                      />
                            {" "}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "30px",
                  marginRight: "50px",
                }}
              >
                {" "}
                <Pagination
                  activePage={page}
                  itemsCountPerPage={limit}
                  totalItemsCount={
                    notifications?.count === undefined
                      ? 0
                      : notifications?.count
                  }
                  pageRangeDisplayed={5}
                  onChange={(page) => handlePageChange(page)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container copy-writes">
          <footer className="row d-flex flex-wrap justify-content-between align-items-center my-4">
            <div className="col-md-4">
              <a
                href="/"
                className="footer-icon mb-3 me-2 mb-md-0 text-muted text-decoration-none"
              >
                <img src="assests/img/footer-icon.svg" alt="" height="15" />
              </a>
              <span className="text-muted">BusinessNET MarketingEngine</span>
              <br />
              <span className="text-muted1">Version 1.0</span>
            </div>

            <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
              <li className="ms-3">
                Privacy policy. Terms of Use. Copyright © 2022 BusinessNET.
              </li>
            </ul>
          </footer>
        </div>
      </div>
    </>
  );
};
