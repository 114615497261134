import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import StyledComponentLibrary from './styles';
import CustomRow from './CustomRow';
import { mapUsersData } from './utils';

const StyledHeaderTableRow = StyledComponentLibrary(TableRow).StyledHeaderTableRow;
const StyledHeaderCell = StyledComponentLibrary(TableCell).StyledHeaderCell;
const StyledBox = StyledComponentLibrary(Box).StyledBox;

const Tasks = (props) => {
    const { tasks, isOpen = false, index, users } = props;

    const renderTableHeader = () => {
        return (
            <StyledHeaderTableRow>
                <StyledHeaderCell><b>Task</b></StyledHeaderCell>
                <StyledHeaderCell><b>Status</b></StyledHeaderCell>
                <StyledHeaderCell><b>Spent Hours</b></StyledHeaderCell>
                <StyledHeaderCell><b>Start Date</b></StyledHeaderCell>
                <StyledHeaderCell><b>Due Date</b></StyledHeaderCell>
                <StyledHeaderCell><b>Assigned to</b></StyledHeaderCell>
            </StyledHeaderTableRow>
        )
    };

    return (
        <div key={index}>
            <Collapse
                in={isOpen}
                timeout="auto"
                addEndListener={() => { }}
                unmountOnExit
            >
                <StyledBox>
                    <Table size="small">
                        <TableHead>
                            {renderTableHeader()}
                        </TableHead>
                        <TableBody>
                            {
                                tasks.map((task, index) => {
                                    return (
                                        <CustomRow
                                            task={task}
                                            index={index}
                                            users={mapUsersData(task, users)}
                                        />
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </StyledBox>
            </Collapse>
        </div>
    )
}

export default Tasks;
