import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const AppName = "I am App context";
  const [timerloading, settimerLoading] = useState(false);
  const [spent_time, setSpentTime] = useState("");
  const [total_time, setTotaltime] = useState("");

  const gettime = async (session_id, time, isActive, load) => {
    if (isActive === true) {
      if (load === true) {
        settimerLoading(true);
      }
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/submit_tracker_time`, {
          session_id,
          time,
          stopped: 1,
        })
        .then((response) => {
          setSpentTime(response.data.spent_minutes);
          setTotaltime(response.data.budgetted_hours);
        });
      settimerLoading(false);
    }
  };
  const timerclose = () => {
    settimerLoading(false);
  };
  useEffect(() => {
    gettime();
  }, []);

  return (
    <AppContext.Provider
      value={{
        AppName,
        gettime,
        timerloading,
        spent_time,
        total_time,
        timerclose,
        setSpentTime,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
